import React from 'react'
import BasicSelect from '../common/basic_select'
import axios from 'axios'
import moment from 'moment'
import Sleep from '../common/sleep'
import html2canvas from 'html2canvas'
import Swal from "sweetalert2";


class GenerateTesterReport extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      createView: true,
      auto: true,
      bugs: [true, true, true, true],
      showPass: true,
      showHighlights: false,
      highlights: [true, false, false, false, false, false],
      highlightsPreview: ["", "", "", "", "", ""],
      highlightsLoading: false,
      prodEnv: true,
      builds: [true, true],
      sort: {label: "Date \u2193", value: "created_at DESC"},
      autoNotes: "",
      autoNextSteps: "",
      tickets: [],
      bugsFiled: 0,
      stepsCompleted: 0,
      devicesTested: 0,
      reportingRange: {label: "Today", value: "today", icons:["https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Today.svg", "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Today_blue.svg", "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Today_Outline_blue.svg"]},
      reportStartDate: moment().format("MM/DD/YY"),
      reportEndDate: moment().format("MM/DD/YY"),
      emailScrollPosition: "start",
      selectedEmailScrollPosition: "start",
      emailDialog: false,
      emailInputFocus: false,
      users: [],
      startingUsers: [],
      selectedEmails: [],
      emailInput: "",
      validEmail: false,
      bugIncrease: null,
      stepIncrease: null,
      deviceIncrease: null,
      manualProject: "",
      manualNotes: "",
      manualNextSteps: "",
      manualBugsFiled: "",
      manualStepsCompleted: "",
      manualDevicesTested: "",
      manualBugLinks: [],
      reviewDisabled: true,
      manualReviewDisabled: true
    }
    this.deltaDivider = typeof InstallTrigger !== 'undefined' ? 0.5 : 4
    this.prioritiesArray = ["Low", "Normal", "High", "Critical"]
    this.regexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  }

  handleEmailOnWheel = (e) => {
    var elem = document.getElementById("report-email-menu")
    scrollHeight = elem.scrollHeight
    clientHeight = elem.clientHeight
    if(scrollHeight > clientHeight){
      e.preventDefault()
      var e = e
      var scrollTop, scrollHeight, clientHeight
      if (e.deltaY > 0){
        elem.scrollTop = elem.scrollTop + Math.floor((e.deltaY/this.deltaDivider))*4
        scrollTop = elem.scrollTop
        
      } else {
        elem.scrollTop = elem.scrollTop - Math.floor(((-e.deltaY)/this.deltaDivider))*4
        scrollTop = elem.scrollTop
      }
      if(scrollTop <= 4){
        if (this.state.emailScrollPosition !== "start"){
          this.setState({
            emailScrollPosition: "start"
          })
        }
      } else if(scrollTop > 4 && scrollHeight - (scrollTop + 5) > clientHeight){
        if (this.state.emailScrollPosition !== "middle"){
          this.setState({
            emailScrollPosition: "middle"
          })
        }
      } else {
        if (this.state.emailScrollPosition !== "end"){
          this.setState({
            emailScrollPosition: "end"
          })
        }
      }
    }
  }
  
  handleSelectedEmailOnWheel = (e) => {
    var elem = document.getElementById("report-email-list")
    scrollHeight = elem.scrollHeight
    clientHeight = elem.clientHeight
    if(scrollHeight > clientHeight){
      e.preventDefault()
      var e = e
      var scrollTop, scrollHeight, clientHeight
      if (e.deltaY > 0){
        elem.scrollTop = elem.scrollTop + Math.floor((e.deltaY/this.deltaDivider))*4
        scrollTop = elem.scrollTop
        
      } else {
        elem.scrollTop = elem.scrollTop - Math.floor(((-e.deltaY)/this.deltaDivider))*4
        scrollTop = elem.scrollTop
      }
      if(scrollTop <= 4){
        if (this.state.selectedEmailScrollPosition !== "start"){
          this.setState({
            selectedEmailScrollPosition: "start"
          })
        }
      } else if(scrollTop > 4 && scrollHeight - (scrollTop + 5) > clientHeight){
        if (this.state.selectedEmailScrollPosition !== "middle"){
          this.setState({
            selectedEmailScrollPosition: "middle"
          })
        }
      } else {
        if (this.state.selectedEmailScrollPosition !== "end"){
          this.setState({
            selectedEmailScrollPosition: "end"
          })
        }
      }
    }
  } 

  componentDidMount(){
    axios.defaults.headers.common = {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN' : document.querySelector('meta[name="csrf-token"]').getAttribute('content')}
      var url = `/v1/projects/${this.props.data.project.id}/user_images.json`
      axios.get(url)
       .then((res) => {
         var users = res.data
         users.sort((a,b) =>{
           if(a.email.toLowerCase() < b.email.toLowerCase()){
            return -1
           } else if(a.email.toLowerCase() > b.email.toLowerCase()){
            return 1
           } else {
             return 0
           }
         } )
         this.setState({
           users,
           startingUsers:  users
          })
       })
       .catch((err) => {
         console.log(err)
       })
    document.body.style.overflow = "hidden"
    var modal = document.querySelector(".new-modal")
    modal.style.top = "0px"
    modal.style.bottom = "0px"
    modal.style.maxHeight = "95%"
    modal.style.overflow = "auto"
    modal.style.backgroundColor = "white"
    modal.style.width = "500px"
    modal.style.maxWidth = "1300px"
    modal.style.border = "solid 2px #519acc"
    modal.classList.add("hide-scrollbar")
    document.querySelector(".new-modal > div:nth-child(1)").style.height = "100%"
    window.html2canvas = html2canvas
  }

  componentWillUnmount(){
    var modal = document.querySelector(".new-modal")
    modal.style.transition = "top 0.3s, transform 0.3s, opacity 0.3s, -webkit-transform 0.3s"
    document.body.style.overflow = "auto"
    window.dispatchEvent(new Event("resize"))
  }

  handleBugCheckbox = (e) => {
    var bugs = this.state.bugs
    bugs[e.target.value] = !bugs[e.target.value]
    this.setState({bugs})
  }

  handleAuto = () => {
    if(this.state.auto){
      var autoNextSteps = this.refs.auto_next_steps.value
      var autoNotes = this.refs.auto_notes.value
      this.setState({
        auto: !this.state.auto,
        autoNotes,
        autoNextSteps
      })
    } else {
      this.setState({
        auto: !this.state.auto,
        manualProject: this.refs.manual_project.value ? this.refs.manual_project.value : "",
        manualNotes: this.refs.manual_notes.value,
        manualNextSteps: this.refs.manual_next_steps.value ? this.refs.manual_next_steps.value : "",
        manualBugsFiled: this.refs.manual_bugs_filed.value,
        manualStepsCompleted: this.refs.manual_steps_completed.value ? this.refs.manual_steps_completed.value : "",
        manualDevicesTested: this.refs.manual_devices_tested.value ? this.refs.manual_devices_tested.value : "",
        manualBugLinks: this.refs.manual_bug_links.value ? this.refs.manual_bug_links.value.replace(/\n/g, " ").replace(/\0/g, " ").replace(/\s{2,}/g, ' ').split(" ") : []
      })
    }
  }

  handlePassShow = () => {
    this.setState({
      showPass: !this.state.showPass
    })
  }

  handleProdEnv = (e) => {
    this.setState({
      prodEnv: e.target.value === "undefined" ? undefined : JSON.parse(e.target.value.toLowerCase())
    })
  }

  handleBuildsCheckbox = (e) => {
    var builds = this.state.builds
    builds[e.target.value] = !builds[e.target.value]
    this.setState({builds})
  }

  handleBackButton = async () => {
    this.props.setBackdropLoader(true)
    var reportElem = document.getElementById("view-report-container")
    var modal = document.querySelector(".new-modal")
    reportElem.classList.remove("report-fade-in")
    reportElem.classList.add("report-fade-out")
    await Sleep(300)
    modal.style.width = "500px"
    await Sleep(300)
    this.setState({
      createView: true 
    },() => {
      
      var createReportElem = document.getElementById("create-report-container")
      createReportElem.style.transition = "opacity 0s"
      createReportElem.style.opacity = "0"
      requestAnimationFrame(() => {
        createReportElem.style.transition = "opacity 0.3s"
        createReportElem.style.opacity = "1"
      })
    })
    this.props.setBackdropLoader(false)

  }

  prioritiesString = () => {
    if(this.state.bugs.indexOf(false) === -1){
      return "All Priorities:"
    } else {
      var pArray = []
      this.state.bugs.forEach((p, i) => {
        if(p){pArray.push(this.prioritiesArray[i])}
      })
      if(pArray.length === 1){
        return `${pArray[0]} Priority:`
      } else if(pArray.length === 2){
        return `${pArray[0]} and ${pArray[1]} Priorities:`
      } else {
        return `${pArray[0]}, ${pArray[1]}, and ${pArray[2]} Priorities:`
      }
    }
  }

  handleCancelButton = () => {
      var self = this
      Swal.fire({
        title: "Discard Report",
        text: "Are you sure you would like to discard this Project Report?",
        reverseButtons: true,
        showCancelButton:true,
confirmButtonAriaLabel: 'Yes',
cancelButtonAriaLabel: 'cancel',
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.props.modalAction(false, "", {})
        } else if (result.dismiss) {

        }
      })
  }

  handleManualReportReview = async () => {
    this.props.setBackdropLoader(true)
    var bugLinks = []
    if(this.refs.manual_bug_links.value){
      bugLinks = this.refs.manual_bug_links.value.replace(/\n/g, " ").replace(/\0/g, " ").replace(/\s{2,}/g, ' ').split(" ")
    }
    document.getElementById("create-report-container").style.opacity = "0"
    await Sleep(300)
    var modal = document.querySelector(".new-modal")
    modal.scrollTop = 0
    modal.style.transition = "width 0.4s, top 0.3s, transform 0.3s, opacity 0.3s, -webkit-transform 0.3s"
    modal.style.width = "90%"
    await Sleep(400)
    this.setState({
      createView: false,
      manualProject: this.refs.manual_project.value ? this.refs.manual_project.value : "",
      manualNotes: this.refs.manual_notes.value,
      manualNextSteps: this.refs.manual_next_steps.value ? this.refs.manual_next_steps.value : "",
      manualBugsFiled: this.refs.manual_bugs_filed.value,
      manualStepsCompleted: this.refs.manual_steps_completed.value ? this.refs.manual_steps_completed.value : "",
      manualDevicesTested: this.refs.manual_devices_tested.value ? this.refs.manual_devices_tested.value : "",
      manualBugLinks: bugLinks
    }, async () => {
      await Sleep(100)
      document.getElementById("report-email-overlay").style.transition = "opacity 0.4s"
    })
    this.props.setBackdropLoader(false)
  }

  handleReportReview = () => {
    this.props.setBackdropLoader(true)
    var autoNextSteps = this.refs.auto_next_steps.value
    var autoNotes = this.refs.auto_notes.value
    var priorities = []
    for(var x = 0; x < 4; x++){
      if(this.state.bugs[3-x]){
        priorities.push(x+1)
      }
    }
    this.setState({autoNextSteps,autoNotes})
    axios.get(`/get_report_data/${this.props.data.project.id}`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }, params:{
        priorities,
        reporting_range: this.state.reportingRange.value,
        sort: this.state.sort.value,
        show_pass: this.state.showPass,
        auto: this.state.auto
      }
    })
    .then(async (res) => {
      var bugIncrease = null, stepIncrease = null, deviceIncrease = null
      var reportHistory = res.data.report_history 
      if(reportHistory.length > 0 && (this.state.reportingRange.value === "today" || this.state.reportingRange.value === "yesterday")){
        if(reportHistory[0].bugs_filed < res.data.ticket_count){
          bugIncrease = true
        } else if(reportHistory[0].bugs_filed > res.data.ticket_count){
          bugIncrease = false
        }
        if(reportHistory[0].steps_completed < res.data.step_count){
          stepIncrease = true
        } else if(reportHistory[0].steps_completed > res.data.step_count){
          stepIncrease = false
        }
        if(reportHistory[0].devices_tested < res.data.device_count){
          deviceIncrease = true
        } else if(reportHistory[0].devices_tested > res.data.device_count){
          deviceIncrease = false
        }
      }
      document.getElementById("create-report-container").style.opacity = "0"
      await Sleep(300)
      var modal = document.querySelector(".new-modal")
      modal.scrollTop = 0
      modal.style.transition = "width 0.4s, top 0.3s, transform 0.3s, opacity 0.3s, -webkit-transform 0.3s"
      modal.style.width = "90%"
      await Sleep(400)
      this.setState({
        createView: false,
        bugsFiled: res.data.ticket_count,
        tickets: res.data.tickets,
        stepsCompleted: res.data.step_count,
        devicesTested: res.data.device_count,
        reportStartDate: moment(res.data.start_date).format("MM/DD/YY"),
        reportEndDate: moment(res.data.end_date).format("MM/DD/YY"),
        bugIncrease,
        stepIncrease,
        deviceIncrease,
        reportHistoryDate: bugIncrease !== null || stepIncrease !== null || deviceIncrease !== null ? reportHistory[0].created_at : false
      }, async () => {
        await Sleep(100)
        document.getElementById("report-email-overlay").style.transition = "opacity 0.4s"
      })
      
      this.props.setBackdropLoader(false)
    })
    .catch((err) => console.error(err))
  }

  handleReportingRange = (reportingRange) => {
    this.setState({
      reportingRange
    }, ()=>{
      if(this.state.showHighlights){
        this.handleHighlightsPreview()
      }
    })
  }

  handleSort = (sort) => {
    this.setState({
      sort
    })
  }

  handleEmailDialog = () => {
    this.setState({
      emailDialog: !this.state.emailDialog
    }, async () => {
      if(this.state.emailDialog){
        document.querySelector(".new-modal").style.overflow = "hidden"
        await Sleep(400)
        document.getElementById("report-email-input").focus()
      } else {
        document.getElementById("report-email-menu").removeEventListener("wheel", this.handleEmailOnWheel, {passive: false})
        document.getElementById("report-email-list").removeEventListener("wheel", this.handleSelectedEmailOnWheel, {passive: false})
        document.querySelector(".new-modal").style.overflow = "auto"
        await Sleep(400)
        this.setState({
          emailInputFocus: false,
          emailInput: "",
          selectedEmails: [],
          users: this.state.startingUsers,
          emailScrollPosition:"start"
        })
      }
    })
  }

  handleEmailInputFocus = () => {
    this.setState({
      emailInputFocus: true
    })
  }

  handleEmailInputBlur = (e) => {
    if(this.state.emailDialog){
      e.target.focus()
    }
  }

  handleAddEmail = (e) => {
    // document.getElementById("report-email-input").focus()
    var email = e.target.dataset.email
    var newUsers = this.state.users.filter((user) => user.email !== email)
    this.setState({
      users: newUsers,
      selectedEmails: [email, ...this.state.selectedEmails]
    })
  }

  handleAddEmailInput = () => {
    document.getElementById("report-email-input").focus()
    var email = this.state.emailInput
    var newUsers = this.state.users.filter((user) => user.email !== email)
    this.setState({
      users: newUsers,
      selectedEmails: [email, ...this.state.selectedEmails],
      emailInput:"", 
      validEmail: false
    })
  }

  handleRemoveEmail = (e) => {
    document.getElementById("report-email-input").focus()
    var email = e.target.dataset.email
    var selectedEmails = this.state.selectedEmails
    selectedEmails.splice(selectedEmails.indexOf(email), 1)
    var newUsers = this.state.users
    var user = this.state.startingUsers.find((user) => user.email === email)
    if(user){
      newUsers.push(user)
      newUsers.sort((a,b) =>{
        if(a.email.toLowerCase() < b.email.toLowerCase()){
         return -1
        } else if(a.email.toLowerCase() > b.email.toLowerCase()){
         return 1
        } else {
          return 0
        }
      })
    }
    this.setState({
      users: newUsers,
      selectedEmails
    })
  }

  handlePDF = () => {
    this.props.setBackdropLoader(true)
    var elem = document.querySelector(".new-modal")
    var cln = elem.cloneNode(true)
    document.body.appendChild(cln)
    cln.style.transition = "none"
    cln.style.border = "none"
    cln.style.maxHeight = `${cln.scrollHeight}px`
    cln.style.height = `${cln.scrollHeight}px`
    cln.style.zIndex = "-1"
    var doc 
    window.scrollTo(0,0)
    html2canvas(cln, {allowTaint:true})
    .then((canvas) => {
      this.props.setBackdropLoader(false)
      document.body.removeChild(cln)
      if(canvas.width > canvas.height){
        doc = new jspdf.jsPDF('l', 'mm', [canvas.width/1.35, canvas.height/1.35]);
      }
      else{
        doc = new jspdf.jsPDF('p', 'mm', [canvas.height/1.35, canvas.width/1.35]);
      }
      var img = canvas.toDataURL("image/png", 0.5);
      doc.addImage(img, 'png', -4, -26, "", "", "", 'FAST');
      doc.save(`${this.props.data.project.name} Report - ${this.state.reportingRange.value === "today" || this.state.reportingRange.value === "yesterday" ? this.state.reportStartDate : `${this.state.reportStartDate} - ${this.state.reportEndDate}`}`);
    })
    
  }

  handleEmailInput = (e) => {
    this.setState({
      emailInput: e.target.value,
      validEmail: this.regexp.test(e.target.value)
    })
  }

  handleSendEmails = () => {
    this.props.setBackdropLoader(true)
    if(this.state.auto){
      axios.post(`/send_report_email`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }, 
          project_report: {
            tickets: this.state.tickets.map((t) =>t.id),
            bugs_filed: this.state.bugsFiled,
            steps_completed: this.state.stepsCompleted,
            devices_tested: this.state.devicesTested,
            notes: this.state.autoNotes,
            next_steps: this.state.autoNextSteps,
            priority_string: this.prioritiesString(),
            project_name: this.props.data.project.name,
            project_id: this.props.data.project.id,
            emails: this.state.selectedEmails,
            show_pass: this.state.showPass,
            environment: this.state.prodEnv === undefined ? "none" : this.state.prodEnv,
            bug_increase: this.state.bugIncrease,
            step_increase: this.state.stepIncrease,
            device_increase: this.state.deviceIncrease,
            show_highlights: this.state.showHighlights && this.state.highlights.indexOf(true) !== -1,
            highlights: this.state.highlights,
            highlights_preview: this.state.highlightsPreview,
            report_history_date: this.state.reportHistoryDate ? moment(this.state.reportHistoryDate).format("MM/DD/YY") : false,
            reporting_range: this.state.reportingRange.value === "today" || this.state.reportingRange.value === "yesterday" ? this.state.reportStartDate : `${this.state.reportStartDate} - ${this.state.reportEndDate}`
          }
      
        }
      )
      .then(async (res) => {
        this.handleEmailDialog()
        this.props.setBackdropLoader(false)
        M.toast({html: `Emails Sent`, displayLength: 3000, classes: 'green' });
      })
      .catch((err) => {
        this.props.setBackdropLoader(false)
        console.error(err)}
      )
    } else {
      axios.post(`/send_report_email`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }, 
          project_report: {
            bug_links: this.state.manualBugLinks,
            bugs_filed: this.state.manualBugsFiled,
            steps_completed: this.state.manualStepsCompleted,
            devices_tested: this.state.manualDevicesTested,
            notes: this.state.manualNotes,
            next_steps: this.state.manualNextSteps,
            project_name: this.state.manualProject ? this.state.manualProject : this.props.data.project.name,
            emails: this.state.selectedEmails,
            reporting_range: moment().format("MM/DD/YY")
          }
      
        }
      )
      .then(async (res) => {
        this.handleEmailDialog()
        this.props.setBackdropLoader(false)
        M.toast({html: `Emails Sent`, displayLength: 3000, classes: 'green' });
      })
      .catch((err) => {
        this.props.setBackdropLoader(false)
        console.error(err)}
      )
    }
    
  }

  handleNumberInput = (e) =>{
    e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1')
    if(e.target.id === "manual-bugs-filed"){
      if(!e.target.value){
        this.setState({
          manualReviewDisabled: true
        })
      } else if(this.refs.manual_notes.value && this.state.manualReviewDisabled){
        this.setState({
          manualReviewDisabled: false
        })
      }
    }
  }

  handleAutoRequiredInput = (e) => {
    if(!e.target.value){this.setState({reviewDisabled: true})
    } else if(this.state.reviewDisabled){this.setState({reviewDisabled: false})
    }
  }

  handleManualRequiredInput = (e) => {
    if(!e.target.value){
      this.setState({manualReviewDisabled: true})
    } else if(this.refs.manual_bugs_filed.value && this.state.manualReviewDisabled){
      this.setState({manualReviewDisabled: false})
    }
  }

  handleShowHighlights = (e) => {
    this.setState({
      showHighlights: !this.state.showHighlights
    }, ()=>{
      if(this.state.showHighlights){
        this.handleHighlightsPreview()
      }
    })
  }

  handleHighlightsCheckbox = (e) => {
    var highlights = this.state.highlights
    highlights[e.target.value] = !highlights[e.target.value]
    this.setState({highlights})
  }

  handleHighlightsPreview = () => {
    this.setState({highlightsLoading: true})
    axios.get(`/get_highlights_preview/${this.props.data.project.id}`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }, params:{
        reporting_range: this.state.reportingRange.value,
      }
    })
    .then(async (res) => {
      var preview = Object.values(res.data)
      this.setState({
        highlightsPreview: preview,
        highlightsLoading: false
      })
    })
    .catch((err) => {
      this.setState({highlightsLoading: false})
      console.error(err)
    })
  }

  render() {
    var {createView, auto, bugs, showPass, prodEnv, builds, highlights} = this.state
    var showHighlights = this.state.showHighlights && highlights.indexOf(true) !== -1
    return (
    <div style={{height:"100%", position:"relative"}}>
      
      {createView ? auto ?
      <div id="create-report-container" style={{padding:"20px 25px", opacity:"1", transition:"opacity 0.3s"}}>
        <div style={{paddingBottom:"20px", borderBottom:"solid 1px gray", fontSize:"13pt", width:"calc(100% + 50px)", paddingLeft:"25px", left:"-25px", position:"relative"}}>
          <span style={{fontWeight:"600"}}>Generate Report</span>
          {" - "}
        <span style={{color:"#519acc"}}>{this.props.data.project?this.props.data.project.name:""}</span>
        </div>
        <div style={{textAlign:"right", padding:"10px 0px 0px 0px"}}>
          <span style={{color:auto?"#519acc":"#242424b8", marginRight:"10px", fontWeight:"600", cursor:"pointer"}}>Automatic</span>
          <span onClick={this.handleAuto} style={{color:!auto?"#519acc":"#242424b8", fontWeight:"600", cursor:"pointer"}}>Manual</span>
        </div>
        <span style={{fontWeight:"600", display:"block", paddingBottom:"5px", fontSize:"11.5pt"}}>Reporting Range</span>
        <BasicSelect
          options={[
            {label: "Today", value: "today", icons:["https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Today.svg", "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Today_blue.svg", "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Today_Outline_blue.svg"]},
            {label: "Yesterday", value: "yesterday", icons:["https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Yesterday.svg","https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Yesterday_blue.svg", "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Yesterday_Outline_Blue.svg"]},
            {label: "Last Week", value: "last_week", icons:["https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Week.svg","https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Week_blue.svg", "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Week_Outline_Blue.svg"]},
            {label: "Last 30 Days", value: "last_30_days", icons:["https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Last%2030%20Days.svg","https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Last%2030%20Days_blue.svg", "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Last_30_Days_Outline_Blue.svg"]}
          ]}
          onChange={this.handleReportingRange} 
          selectedOption={this.state.reportingRange}
          selectedIconStyle={{height: "35px", width:"35px", verticalAlign:"middle", marginRight:"5px"}}
          listItemStyle={{paddingTop:"4px", paddingBottom:"4px", paddingLeft:"18px"}}
          selectedItemStyle={{display:"flex", justifyContent:"space-around"}}
          // arrowStyle={{position:"relative", lineHeight:"10px", height:"10px", top:"12px", right:"0px"}}          selectedTextStyle={{display:"inline-block", minWidth:"96px", textAlign:"center"}}
          listItemImgStyle={{paddingRight:"10px", width:"40px"}}
          id="1"
          width="168px"
          maxHeight="155px"
        />
        {/* <span role="heading" style={{fontWeight:"600", padding:"15px 0px 10px", display:"block", fontSize:"11pt"}}>Project Specifics</span> */}
       <div style={{marginTop:"13px"}}>
          <span style={{fontSize:"11.5pt", color:"#242424", fontWeight:"600"}}>Environment</span>
          <div className="common-radio" style={{padding:"10px 0px"}}>
            <label>
              <input style={{width:"100px"}} onChange={this.handleProdEnv} className="with-gap" checked={prodEnv===false} value={false} type="radio"/>
              <span style={{whiteSpace:"nowrap", fontSize:"11pt", color:"#242424", marginRight:"20px", paddingLeft:"28px"}}>Staging</span>
            </label>
            <label>
              <input style={{width:"100px"}} onChange={this.handleProdEnv} className="with-gap" checked={prodEnv===true} value={true} type="radio"/>
              <span style={{whiteSpace:"nowrap", fontSize:"11pt", color:"#242424", marginRight:"20px", paddingLeft:"28px"}}>Production</span>
            </label>
            <label>
              <input style={{width:"100px"}} onChange={this.handleProdEnv} className="with-gap" checked={prodEnv===undefined} value={"undefined"} type="radio"/>
              <span style={{whiteSpace:"nowrap", fontSize:"11pt", color:"#242424", paddingLeft:"28px"}}>N/A</span>
            </label>
          </div>
        </div>
        <div style={{marginTop:"5px"}}>
          <span style={{fontSize:"11.5pt", color:"#242424", fontWeight:"600"}}>Highlights</span>
          <div className="common-radio" style={{padding:"10px 0px", position:"relative"}}>
            <label>
              <input style={{width:"100px"}} onChange={this.handleShowHighlights} className="with-gap" checked={this.state.showHighlights} value={true} type="radio"/>
              <span style={{whiteSpace:"nowrap", fontSize:"11pt", color:"#242424", marginRight:"20px", paddingLeft:"28px"}}>Show</span>
            </label>
            <label>
              <input style={{width:"100px"}} onChange={this.handleShowHighlights} className="with-gap" checked={!this.state.showHighlights} value={false} type="radio"/>
              <span style={{whiteSpace:"nowrap", fontSize:"11pt", color:"#242424", marginRight:"20px", paddingLeft:"28px"}}>Hide</span>
            </label>
            <span style={{position:"absolute", color:"#afafaf", fontSize:"9pt", display:"block", right:"166px", bottom:"-13px", opacity:this.state.showHighlights?"1":"0", transition:"opacity 0.2s"}}>Preview</span>
          </div>
          <div id="highlights-container" style={{transition: "height 0.3s", whiteSpace:"nowrap", margin:"0px 0px 0px 10px", borderLeft: "solid 1px lightgray", height: this.state.showHighlights? this.props.data.project.a11y_active ? "210px" : "180px":"0px", overflow:"hidden"}}>
            <div className="common-checkbox" style={{display:"inline-block", padding:"10px", verticalAlign:"top"}}>
              <label style={{display:"block", marginBottom:"5px"}}>
                <input style={{width:"100px"}} onChange={this.handleHighlightsCheckbox} className="filled-in" checked={highlights[0]} value={0} type="checkbox"/>
                <span style={{whiteSpace:"nowrap", paddingLeft:"28px", marginRight:"20px", color:"#242424", fontSize:"11pt"}}>Critical bugs filed</span>
              </label>
              <label style={{display:"block", marginBottom:"5px"}}>
                <input style={{width:"100px"}} onChange={this.handleHighlightsCheckbox} className="filled-in" checked={highlights[1]} value={1} type="checkbox"/>
                <span style={{whiteSpace:"nowrap", paddingLeft:"28px", marginRight:"20px", color:"#242424", fontSize:"11pt"}}>Bugs fixed or closed</span>
              </label>
              <label style={{display:"block", marginBottom:"5px"}}>
                <input style={{width:"100px"}} onChange={this.handleHighlightsCheckbox} className="filled-in" checked={highlights[2]} value={2} type="checkbox"/>
                <span style={{whiteSpace:"nowrap", paddingLeft:"28px", marginRight:"20px", color:"#242424", fontSize:"11pt"}}>Browser with most bugs</span>
              </label>
              <label style={{display:"block", marginBottom:"5px"}}>
                <input style={{width:"100px"}} onChange={this.handleHighlightsCheckbox} className="filled-in" checked={highlights[3]} value={3} type="checkbox"/>
                <span style={{whiteSpace:"nowrap", paddingLeft:"28px", color:"#242424", fontSize:"11pt"}}>Test Passes completed</span>
              </label>
              <label style={{display:"block", marginBottom:"5px"}}>
                <input style={{width:"100px"}} onChange={this.handleHighlightsCheckbox} className="filled-in" checked={highlights[4]} value={4} type="checkbox"/>
                <span style={{whiteSpace:"nowrap", paddingLeft:"28px", color:"#242424", fontSize:"11pt"}}>Builds Uploaded</span>
              </label>
              {this.props.data.project.a11y_active && <label style={{display:"block"}}>
                <input style={{width:"100px"}} onChange={this.handleHighlightsCheckbox} className="filled-in" checked={highlights[5]} value={5} type="checkbox"/>
                <span style={{whiteSpace:"nowrap", paddingLeft:"28px", color:"#242424", fontSize:"11pt"}}>Top WCAG</span>
              </label>}
            </div>
            <div id="preview-container" style={{position:"relative", display:"inline-block", marginTop:"8px", padding:"2px 10px", backgroundColor:"#ececec", height: this.props.data.project.a11y_active ?"200px":"168px", borderRadius:"5px", maxWidth:"210px", border:"solid 1px lightgray", minWidth:"100px"}}>
              <div id="preview-loading-overlay" style={{position:"absolute", backgroundColor:"#00000021", display:"flex", justifyContent:"center", alignItems:"center", left:"0px", top:"0px", width:"100%", height:"100%", borderRadius:"5px", opacity: this.state.highlightsLoading?"1":"0", pointerEvents: this.state.highlightsLoading?"all":"none", transition:"opacity 0.3s"}}>
                {this.state.highlightsLoading && <div style={{width:"20px", height:"20px", borderColor:"#f9f9f9", borderTopColor:"#0097ff"}} id="test-step-check-bug-loader"/>}
              </div>
              <div style={{marginBottom:"5px"}}>{this.state.highlightsPreview[0]}</div>
              <div style={{marginBottom:"5px"}}>{this.state.highlightsPreview[1]}</div>
              <div style={{marginBottom:"5px"}}>{this.state.highlightsPreview[2]}</div>
              <div style={{marginBottom:"5px"}}>{this.state.highlightsPreview[3]}</div>
              <div style={{marginBottom:"5px"}}>{this.state.highlightsPreview[4]}</div>
              {this.props.data.project.a11y_active && <div style={{overflow:"hidden", textOverflow:"ellipsis"}}>{this.state.highlightsPreview[5]}</div>}
            </div>
          </div>
        </div>
        <div style={{marginTop:"5px"}}>
          <span style={{display:"block"}}><span style={{fontSize:"11.5pt", color:"#242424", fontWeight:"600"}}>Bugs</span>{" - Sort By: "}
            <BasicSelect
            // Alex, I did a security audit discovered its not safe to send order strings so I mapped these in the controller - Dale"
              options={[
                {label: "Date \u2193", value: "555"},
                {label: "Date \u2191", value: "666"},
                {label: "Priority \u2193", value: "777"},
                {label: "Priority \u2191", value: "888"}
              ]}
              onChange={this.handleSort} 
              selectedOption={this.state.sort}
              id="2"
              width="120px"
              maxHeight="145px"
              display="inline-block"
            />
          </span>
          <div className="common-checkbox" style={{padding:"10px 0px"}}>
            <label>
              <input style={{width:"100px"}} onChange={this.handleBugCheckbox} className="filled-in" checked={bugs[0]} value={0} type="checkbox"/>
              <span style={{whiteSpace:"nowrap", paddingLeft:"28px", marginRight:"20px", color:"#242424", fontSize:"11pt"}}>Low</span>
            </label>
            <label>
              <input style={{width:"100px"}} onChange={this.handleBugCheckbox} className="filled-in" checked={bugs[1]} value={1} type="checkbox"/>
              <span style={{whiteSpace:"nowrap", paddingLeft:"28px", marginRight:"20px", color:"#242424", fontSize:"11pt"}}>Normal</span>
            </label>
            <label>
              <input style={{width:"100px"}} onChange={this.handleBugCheckbox} className="filled-in" checked={bugs[2]} value={2} type="checkbox"/>
              <span style={{whiteSpace:"nowrap", paddingLeft:"28px", marginRight:"20px", color:"#242424", fontSize:"11pt"}}>High</span>
            </label>
            <label>
              <input style={{width:"100px"}} onChange={this.handleBugCheckbox} className="filled-in" checked={bugs[3]} value={3} type="checkbox"/>
              <span style={{whiteSpace:"nowrap", paddingLeft:"28px", color:"#242424", fontSize:"11pt"}}>Critical</span>
            </label>
          </div>
        </div>
        <div>
          <span style={{fontSize:"11.5pt", color:"#242424", fontWeight:"600"}}>Test Passes</span>
          <div className="common-radio" style={{padding:"10px 0px"}}>
            <label>
              <input style={{width:"100px"}} onChange={this.handlePassShow} className="with-gap" checked={showPass} value={true} type="radio"/>
              <span style={{whiteSpace:"nowrap", fontSize:"11pt", color:"#242424", marginRight:"20px", paddingLeft:"28px"}}>Show</span>
            </label>
            <label>
              <input style={{width:"100px"}} onChange={this.handlePassShow} className="with-gap" checked={!showPass} value={false} type="radio"/>
              <span style={{whiteSpace:"nowrap", fontSize:"11pt", color:"#242424", paddingLeft:"28px"}}>Hide</span>
            </label>
          </div>
        </div>
        {/* <div>
          <span style={{fontSize:"11.5pt", color:"#242424", fontWeight:"600"}}>Builds</span>
          <div className="common-checkbox" style={{padding:"10px 0px"}}>
            <label>
              <input style={{width:"100px"}} onChange={this.handleBuildsCheckbox} className="filled-in" checked={builds[0]} value={0} type="checkbox"/>
              <span style={{whiteSpace:"nowrap", paddingLeft:"28px", marginRight:"20px", color:"#242424", fontSize:"11pt"}}>Android</span>
            </label>
            <label>
              <input style={{width:"100px"}} onChange={this.handleBuildsCheckbox} className="filled-in" checked={builds[1]} value={1} type="checkbox"/>
              <span style={{whiteSpace:"nowrap", paddingLeft:"28px", color:"#242424", fontSize:"11pt"}}>iOS</span>
            </label>
          </div>
        </div> */}
        <div>
          <span style={{fontSize:"11.5pt", color:"#242424", fontWeight:"600"}}>Notes From Project Manager*</span>
          <textarea defaultValue={this.state.autoNotes} onChange={this.handleAutoRequiredInput} maxLength={3000} style={{padding:"10px 0px", marginTop:"10px"}} ref="auto_notes" className="common-textarea" placeholder="Any additional comments or need to know information about the status of the project."/>
        </div>
        <div style={{marginBottom:"10px"}}>
          <span style={{fontSize:"11.5pt", color:"#242424", fontWeight:"600"}}>Next Steps</span>
          <textarea defaultValue={this.state.autoNextSteps} maxLength={3000} style={{padding:"10px 0px", marginTop:"10px"}} ref="auto_next_steps" className="common-textarea" placeholder="Work that has yet to be completed or is planned to be completed next."/>
        </div>
        <div style={{textAlign:"right", marginTop:"10px"}}>
          <button onClick={this.handleCancelButton} className="common-button-cancel">Cancel</button>
          <button disabled={this.state.reviewDisabled} onClick={this.handleReportReview} className="common-button-submit">Review</button>
        </div>
      </div>:
      <div id="create-report-container" style={{padding:"20px 25px", opacity:"1", transition:"opacity 0.3s"}}>
        <div style={{paddingBottom:"20px", borderBottom:"solid 1px gray", fontSize:"13pt", width:"calc(100% + 50px)", paddingLeft:"25px", left:"-25px", position:"relative"}}>
          <span style={{fontWeight:"600"}}>Generate Report</span>
          {" - "}
          <span style={{color:"#519acc"}}>{this.props.data.project?this.props.data.project.name:""}</span>
        </div>
        <div style={{textAlign:"right", padding:"10px 0px 0px 0px"}}>
          <span onClick={this.handleAuto} style={{color:auto?"#519acc":"#242424b8", marginRight:"10px", fontWeight:"600", cursor:"pointer"}}>Automatic</span>
          <span style={{color:!auto?"#519acc":"#242424b8", fontWeight:"600", cursor:"pointer"}}>Manual</span>
        </div>
        <div style={{marginBottom:"10px"}}>
          <span style={{fontSize:"11.5pt", color:"#242424", fontWeight:"600"}}>Project</span>
          <input maxLength={50} defaultValue={this.state.manualProject} style={{padding:"10px 0px", marginTop:"10px", width:"250px", display: "block"}} ref="manual_project" className="common-input" placeholder="If different than project in heading."/>
        </div>
        <div style={{marginBottom:"10px"}}>
          <span style={{fontSize:"11.5pt", color:"#242424", fontWeight:"600"}}>Number of Bugs Filed*</span>
          <input maxLength={3} defaultValue={this.state.manualBugsFiled} onChange={this.handleNumberInput} id="manual-bugs-filed" style={{padding:"10px 0px", marginTop:"10px", width:"80px", display: "block"}} ref="manual_bugs_filed" className="common-input" placeholder="#"/>
        </div>
        <div style={{marginBottom:"10px"}}>
          <span style={{fontSize:"11.5pt", color:"#242424", fontWeight:"600"}}>Bug Links</span>
          <textarea maxLength={4000} defaultValue={this.state.manualBugLinks.join(" ")} style={{padding:"10px 0px", marginTop:"10px"}} ref="manual_bug_links" className="common-textarea" placeholder="Provide unique URLs for each bug filed today, separated by spaces or line breaks."/>
        </div>
        <div style={{marginBottom:"10px"}}>
          <span style={{fontSize:"11.5pt", color:"#242424", fontWeight:"600"}}>Number of Steps Completed</span>
          <input maxLength={4} defaultValue={this.state.manualStepsCompleted} onChange={this.handleNumberInput} style={{padding:"10px 0px", marginTop:"10px", width:"80px", display: "block"}} ref="manual_steps_completed" className="common-input" placeholder="#"/>
        </div>
        <div style={{marginBottom:"10px"}}>
          <span style={{fontSize:"11.5pt", color:"#242424", fontWeight:"600"}}>Number of Devices Tested</span>
          <input maxLength={3} defaultValue={this.state.manualDevicesTested} onChange={this.handleNumberInput} style={{padding:"10px 0px", marginTop:"10px", width:"80px", display: "block"}} ref="manual_devices_tested" className="common-input" placeholder="#"/>
        </div>
        <div>
          <span style={{fontSize:"11.5pt", color:"#242424", fontWeight:"600"}}>Notes From Project Manager*</span>
          <textarea maxLength={3000} defaultValue={this.state.manualNotes} onChange={this.handleManualRequiredInput} style={{padding:"10px 0px", marginTop:"10px"}} ref="manual_notes" className="common-textarea" placeholder="Any additional comments or need to know information about the status of the project."/>
        </div>
        <div style={{marginBottom:"10px"}}>
          <span style={{fontSize:"11.5pt", color:"#242424", fontWeight:"600"}}>Next Steps</span>
          <textarea maxLength={3000} defaultValue={this.state.manualNextSteps} style={{padding:"10px 0px", marginTop:"10px"}} key="manual_next_steps" ref="manual_next_steps" className="common-textarea" placeholder="Work that has yet to be completed or is planned to be completed next."/>
        </div>
        <div style={{textAlign:"right", marginTop:"10px"}}>
          <button onClick={this.handleCancelButton} className="common-button-cancel">Cancel</button>
          <button disabled={this.state.manualReviewDisabled} onClick={this.handleManualReportReview} className="common-button-submit">Review</button>
        </div>
      </div>
      :
      <div id="view-report-container" className="report-fade-in" style={{margin: "0px auto", padding:"0px 40px", maxWidth:"1280px", position:"relative", height:"100%"}}>
        <div id="report-email-overlay" style={{position:"absolute", left:"-6px", top:"0px", backgroundColor:"black", opacity:this.state.emailDialog?"0.3":"0", pointerEvents:this.state.emailDialog?"all":"none", width:"102%", height:"100%", zIndex:"3"}}/>
        <span data-html2canvas-ignore="true" onClick={this.handleBackButton} id="report-back-button" style={{cursor:"pointer", position:"absolute", left:"17px", top:"8px", display:"block", zIndex:"2", color:"#83c9f7"}}>{'\u2b60'} Edit Report</span>
        <div  id="report-nav" style={{width:"100%", height:"40px", borderBottom:"solid 1px lightgray", position:"relative"}}>
          <img data-html2canvas-ignore="true" src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Report%20-%20PDF.svg" onClick={this.handlePDF} style={{display:"inline-block", marginLeft:"88px", height:"29px", verticalAlign:"text-top", cursor:"pointer"}}/>
          <img data-html2canvas-ignore="true" src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Report%20-%20Email.svg" onClick={this.handleEmailDialog} style={{display:"inline-block", marginLeft:"22px", height:"29px", verticalAlign:"text-top", cursor:"pointer"}}/>
          <div id="email-dialog" style={{zIndex: "4", overflow: "hidden", whiteSpace:"nowrap", width:this.state.emailDialog? "516px" : "0px", height: this.state.emailDialog? "248px" : "0px", position:"absolute", top:"40px", left:"141px", backgroundColor:"white", border:"solid 1px #519acc", opacity:this.state.emailDialog?"1":"0", borderRadius:"5px", transition:"width 0.4s, height 0.4s, opacity 0.4s"}}>
              <i className="material-icons" onClick={this.handleAddEmailInput} style={{position:"absolute", display:"block", top:"34px", left:"247px", fontSize:"20pt", color:this.state.validEmail?"#519acc":"lightgray", cursor:this.state.validEmail?"pointer":"default", pointerEvents:this.state.validEmail?"all":"none"}}>keyboard_arrow_right</i>
              <div style={{width:"200px", display:"inline-block", position:"relative"}}>
                <input value={this.state.emailInput} maxLength={50} onChange={this.handleEmailInput} onBlur={this.handleEmailInputBlur} onFocus={this.handleEmailInputFocus} id="report-email-input" style={{width:"230px", display:"block", fontSize:"10pt"}} placeholder="Enter or Select Email"></input>
                <div id="report-email-menu" style={{maxHeight:this.state.emailInputFocus?"171px":"0px", width:"230px", fontSize:"10pt", position:"absolute", top:"60px", left:"18px", zIndex:this.state.emailInputFocus?"1":"-1", transition:"max-height 0.3s", borderBottom:"solid 1px #519acc", borderLeft:"solid 1px #519acc", borderRight:"solid 1px #519acc", borderBottomLeftRadius:"10px", borderBottomRightRadius:"10px"}}>
                  {this.state.users.filter((user) => user.email.includes(this.state.emailInput)).map((user, index) => {
                    return(
                      <div onClick={this.handleAddEmail} key={user.id} data-email={user.email} style={{width:"100%", paddingTop: index === 0 ? "2px" : "0px"}}>
                        <span style={{paddingLeft:"8px", pointerEvents:"none"}}>{user.email}</span>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div style={{width:"235px", display:"inline-block", position:"relative", marginLeft:"71px", padding:"6px 10px 10px 0px", verticalAlign:"top"}}>

                <span style={{display:"block", fontWeight:"600", fontSize:"10pt", lineHeight:"20pt"}}>Email List</span>
                <div id="report-email-list" style={{height:"150px", overflow:"auto", backgroundColor:"#f5f5f5", borderRadius:"5px", border:"2px solid #e0e0e0"}}>
                  {this.state.selectedEmails.map((email, index) => {
                      return(
                        <div key={email} data-email={email} style={{width:"100%", position:"relative"}}>
                          <i data-email={email} onClick={this.handleRemoveEmail} style={{display:"none", position:"absolute", right:"5px", top:"6px", fontSize:"12pt", cursor:"pointer"}} className="material-icons">close</i>
                          <span style={{paddingLeft:"8px", pointerEvents:"none"}}>{email}</span>
                        </div>
                      )
                    })}
                </div>
              </div>
              <div style={{width:"497px", marginRight:"14px", textAlign:"right", marginTop:"5px"}}>
                <button onClick={this.handleEmailDialog} style={{width:"90px", height:"34px"}} className="common-button-cancel">Cancel</button>
                <button onClick={this.handleSendEmails} style={{width:"90px", height:"34px", marginLeft:"47px"}} disabled={this.state.selectedEmails.length === 0} className="common-button-submit">Send</button>
              </div>
          </div>
        </div>
        {this.state.auto ? <div id="report-heading" style={{width:"100%"}}>
          {showHighlights?
            <div style={{display:"inline-block", height:"260px", position:"relative", marginTop:showHighlights?"10px":"0px"}} id="report-heading-left">
              <div style={{display:"flex", alignItems:"center"}}>
                {this.props.data.project.icon_base64?
                  <img src={`data:image/png;base64, ${this.props.data.project.icon_base64}`} style={{borderRadius:"50%", height:"50px", width:"50px", margin:"0px 20px 0px 10px"}} />:
                  <div style={{borderRadius:"50%", height:"50px", width:"50px", margin:"0px 20px 0px 10px", fontSize:"16pt", color:"white", display:"flex", justifyContent:"center", alignItems:"center", backgroundColor:"#519acc"}}>
                    {this.props.data.project.name[0].toUpperCase()}
                  </div>
                }
                <div style={{minHeight:this.state.prodEnv===true || this.state.prodEnv===false ? "100px":"85px", paddingTop:"14px"}}>
                  <span style={{display:"block", color:"#519acc", fontSize:"20pt"}}>{this.props.data.project.name}</span>
                  <span style={{display:"block", fontSize:"11pt"}}>
                  Report for: 
                  {this.state.reportingRange.value === "today" || this.state.reportingRange.value === "yesterday" ? 
                  <span style={{color:"#519acc"}}> {this.state.reportStartDate}</span>:
                  <span style={{color:"#519acc"}}> {this.state.reportStartDate} - {this.state.reportEndDate}</span>}
                  </span>
                  {(this.state.prodEnv===true || this.state.prodEnv===false) && <span style={{display:"block", color:"#777777", lineHeight:"18px", fontStyle:"italic"}}>{this.state.prodEnv===true?"Production ":"Staging "} Testing</span>}
                </div>
              </div>
              <div style={{width:"92%", backgroundColor:"lightgray", height:"1px"}}/>
              <div id="report-heading-right" style={{display: "flex", width:"100%", border: "none"}}>
                <div style={{display:"flex", flexDirection:"column", alignItems:"center", position:"relative"}}>
                  {(this.state.reportHistoryDate && this.state.stepsCompleted === null) &&
                    <span style={{position:"absolute", display:"block", width:"179px", fontSize:"8pt", color:"#a5a5a5", bottom:"-21px"}}>(Compared to last report on {moment(this.state.reportHistoryDate).format("MM/DD/YY")})</span>
                  }
                  <img style={{height:"40px", width:"40px", display:"block", marginBottom:"15px"}} src="/Icons_Bugs_Filled.png"/>
                  <span style={{display:"block", color:"#519acc", fontSize:"20pt", marginBottom:"5px"}}>
                    {this.state.bugsFiled}
                    {this.state.bugIncrease === true ? <span style={{display:"inline-block", width:"0px", height:"0px", verticalAlign:"4px", marginLeft:"6px", borderBottom:"solid 10px #00da00", borderLeft:"solid 7px transparent", borderRight:"solid 7px transparent"}}/>
                      : this.state.bugIncrease === false ? <span style={{display:"inline-block", width:"0px", height:"0px", verticalAlign:"4px", marginLeft:"6px", borderTop:"solid 10px red", borderLeft:"solid 7px transparent", borderRight:"solid 7px transparent"}} /> :
                      ""}
                  </span>
                  <span style={{display:"block", fontSize:"10pt"}}>Bugs Filed</span>
                </div>
                {this.state.stepsCompleted !== null && <div style={{display:"flex", flexDirection:"column", alignItems:"center", position:"relative"}}>
                  {this.state.reportHistoryDate && 
                    <span style={{position:"absolute", display:"block", width:"179px", fontSize:"8pt", color:"#a5a5a5", bottom:"-21px"}}>(Compared to last report on {moment(this.state.reportHistoryDate).format("MM/DD/YY")})</span>
                  }
                  <img style={{height:"40px", width:"40px", display:"block", marginBottom:"15px"}} src="/Icons_Test_Cases_Filled.png"/>
                  <span style={{display:"block", color:"#519acc", fontSize:"20pt", marginBottom:"5px"}}>
                    {this.state.stepsCompleted}
                    {this.state.stepIncrease === true ? <span style={{display:"inline-block", width:"0px", height:"0px", verticalAlign:"4px", marginLeft:"6px", borderBottom:"solid 10px #00da00", borderLeft:"solid 7px transparent", borderRight:"solid 7px transparent"}}/>
                      : this.state.stepIncrease === false ? <span style={{display:"inline-block", width:"0px", height:"0px", verticalAlign:"4px", marginLeft:"6px", borderTop:"solid 10px red", borderLeft:"solid 7px transparent", borderRight:"solid 7px transparent"}} /> :
                      ""}
                  </span>
                  <span style={{display:"block", fontSize:"10pt"}}>Steps Completed</span>
                </div>}
                {this.state.devicesTested !== null && <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                  <img style={{height:"40px", width:"40px", display:"block", marginBottom:"15px"}} src="/device_lab_filled.png"/>
                  <span style={{display:"block", color:"#519acc", fontSize:"20pt", marginBottom:"5px"}}>
                    {this.state.devicesTested}
                    {this.state.deviceIncrease === true ? <span style={{display:"inline-block", width:"0px", height:"0px", verticalAlign:"4px", marginLeft:"6px", borderBottom:"solid 10px #00da00", borderLeft:"solid 7px transparent", borderRight:"solid 7px transparent"}}/>
                      : this.state.deviceIncrease === false ? <span style={{display:"inline-block", width:"0px", height:"0px", verticalAlign:"4px", marginLeft:"6px", borderTop:"solid 10px red", borderLeft:"solid 7px transparent", borderRight:"solid 7px transparent"}} /> :
                      ""}
                  </span>
                  <span style={{display:"block", fontSize:"10pt"}}>Devices Tested</span>
                </div>}
              </div>
              <div style={{display:"block", position:"absolute", right:"0px", top:"22px", width:"2px", height:"210px", backgroundColor:"#e4e4e4"}} />
            </div>
            :
            <div id="report-heading-left">
            {this.props.data.project.icon_base64?
              <img src={`data:image/png;base64, ${this.props.data.project.icon_base64}`} style={{borderRadius:"50%", height:"50px", width:"50px", margin:"0px 20px 0px 10px"}} />:
              <div style={{borderRadius:"50%", height:"50px", width:"50px", margin:"0px 20px 0px 10px", fontSize:"16pt", color:"white", display:"flex", justifyContent:"center", alignItems:"center", backgroundColor:"#519acc"}}>
                {this.props.data.project.name[0].toUpperCase()}
              </div>
            }
            <div style={{height:this.state.prodEnv===true || this.state.prodEnv===false ? "65px":"50px"}}>
              <span style={{display:"block", color:"#519acc", fontSize:"20pt"}}>{this.props.data.project.name}</span>
              <span style={{display:"block", fontSize:"11pt"}}>
              Report for: 
              {this.state.reportingRange.value === "today" || this.state.reportingRange.value === "yesterday" ? 
              <span style={{color:"#519acc"}}> {this.state.reportStartDate}</span>:
              <span style={{color:"#519acc"}}> {this.state.reportStartDate} - {this.state.reportEndDate}</span>}
              </span>
            {(this.state.prodEnv===true || this.state.prodEnv===false) && <span style={{display:"block", color:"#777777", lineHeight:"18px", fontStyle:"italic"}}>{this.state.prodEnv===true?"Production ":"Staging "} Testing</span>}
              </div>
            </div>
          }
          {showHighlights ? 
          <div id="report-heading-right" style={{height:"260px", flexDirection:"column", justifyContent:"center", alignItems:"flex-start", fontSize:"12pt", paddingLeft:"4%"}}>
            <span style={{fontSize:"12pt", fontWeight:"600", display:"block", marginBottom:"10px"}}>Highlights:</span>
            {this.state.highlights[0] && <span style={{display:"block", lineHeight:"30px"}}><span style={{color:"#519acc"}}>&#8226;{"    "}{this.state.highlightsPreview[0]} Critical bugs</span> were filed</span>}
            {this.state.highlights[1] && <span style={{display:"block", lineHeight:"30px"}}><span style={{color:"#519acc"}}>&#8226;{"    "}{this.state.highlightsPreview[1]} existing bugs</span> were marked as <span style={{color:"#519acc"}}>Fixed or Closed</span></span>}
            {this.state.highlights[2] && <span style={{display:"block", lineHeight:"30px"}}>&#8226;{"    "}Browser with most bugs was <span style={{color:"#519acc"}}>{this.state.highlightsPreview[2]}</span></span>}
            {this.state.highlights[3] && <span style={{display:"block", lineHeight:"30px"}}><span style={{color:"#519acc"}}>&#8226;{"    "}{this.state.highlightsPreview[3]} Test Passes</span> were completed</span>}
            {this.state.highlights[4] && <span style={{display:"block", lineHeight:"30px"}}><span style={{color:"#519acc"}}>&#8226;{"    "}{this.state.highlightsPreview[4]} Builds</span> were uploaded</span>}
            {this.state.highlights[5] && <span style={{display:"block", lineHeight:"30px"}}><span style={{color:"#519acc"}}>&#8226;{"    "}Top WCAG</span> violation was <span style={{color:"#519acc"}}>{this.state.highlightsPreview[5]}</span></span>}
          </div>
          :
          <div id="report-heading-right">
            <div style={{display:"flex", flexDirection:"column", alignItems:"center", position:"relative"}}>
              {(this.state.reportHistoryDate && this.state.stepsCompleted === null) &&
                <span style={{position:"absolute", display:"block", width:"179px", fontSize:"8pt", color:"#a5a5a5", bottom:"-21px"}}>(Compared to last report on {moment(this.state.reportHistoryDate).format("MM/DD/YY")})</span>
              }
              <img style={{height:"40px", width:"40px", display:"block", marginBottom:"15px"}} src="/Icons_Bugs_Filled.png"/>
              <span style={{display:"block", color:"#519acc", fontSize:"20pt", marginBottom:"5px"}}>
                {this.state.bugsFiled}
                {this.state.bugIncrease === true ? <span style={{display:"inline-block", width:"0px", height:"0px", verticalAlign:"4px", marginLeft:"6px", borderBottom:"solid 10px #00da00", borderLeft:"solid 7px transparent", borderRight:"solid 7px transparent"}}/>
                  : this.state.bugIncrease === false ? <span style={{display:"inline-block", width:"0px", height:"0px", verticalAlign:"4px", marginLeft:"6px", borderTop:"solid 10px red", borderLeft:"solid 7px transparent", borderRight:"solid 7px transparent"}} /> :
                  ""}
              </span>
              <span style={{display:"block", fontSize:"10pt"}}>Bugs Filed</span>
            </div>
            {this.state.stepsCompleted !== null && <div style={{display:"flex", flexDirection:"column", alignItems:"center", position:"relative"}}>
              {this.state.reportHistoryDate && 
                <span style={{position:"absolute", display:"block", width:"179px", fontSize:"8pt", color:"#a5a5a5", bottom:"-21px"}}>(Compared to last report on {moment(this.state.reportHistoryDate).format("MM/DD/YY")})</span>
              }
              <img style={{height:"40px", width:"40px", display:"block", marginBottom:"15px"}} src="/Icons_Test_Cases_Filled.png"/>
              <span style={{display:"block", color:"#519acc", fontSize:"20pt", marginBottom:"5px"}}>
                {this.state.stepsCompleted}
                {this.state.stepIncrease === true ? <span style={{display:"inline-block", width:"0px", height:"0px", verticalAlign:"4px", marginLeft:"6px", borderBottom:"solid 10px #00da00", borderLeft:"solid 7px transparent", borderRight:"solid 7px transparent"}}/>
                  : this.state.stepIncrease === false ? <span style={{display:"inline-block", width:"0px", height:"0px", verticalAlign:"4px", marginLeft:"6px", borderTop:"solid 10px red", borderLeft:"solid 7px transparent", borderRight:"solid 7px transparent"}} /> :
                  ""}
              </span>
              <span style={{display:"block", fontSize:"10pt"}}>Steps Completed</span>
            </div>}
            {this.state.devicesTested !== null && <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
              <img style={{height:"40px", width:"40px", display:"block", marginBottom:"15px"}} src="/device_lab_filled.png"/>
              <span style={{display:"block", color:"#519acc", fontSize:"20pt", marginBottom:"5px"}}>
                {this.state.devicesTested}
                {this.state.deviceIncrease === true ? <span style={{display:"inline-block", width:"0px", height:"0px", verticalAlign:"4px", marginLeft:"6px", borderBottom:"solid 10px #00da00", borderLeft:"solid 7px transparent", borderRight:"solid 7px transparent"}}/>
                  : this.state.deviceIncrease === false ? <span style={{display:"inline-block", width:"0px", height:"0px", verticalAlign:"4px", marginLeft:"6px", borderTop:"solid 10px red", borderLeft:"solid 7px transparent", borderRight:"solid 7px transparent"}} /> :
                  ""}
              </span>
              <span style={{display:"block", fontSize:"10pt"}}>Devices Tested</span>
            </div>}
          </div>}
          
          {this.state.autoNotes && <div id="report-notes" style={{width:"100%", borderBottom:"solid 1px lightgray"}}>
            <span style={{display:"block", fontWeight:"600", fontSize:"10pt", margin:"15px 0px 5px 0px"}}>Notes from the Project Manager:</span>
            <span style={{display:"block", marginBottom:"20px", fontSize:"10pt", lineHeight:"20px"}}>{this.state.autoNotes}</span>
          </div>}
          {this.state.autoNextSteps && <div id="report-next-steps" style={{width:"100%", borderBottom:"solid 1px lightgray"}}>
            <span style={{display:"block", fontWeight:"600", fontSize:"10pt", margin:"15px 0px 5px 0px"}}>Next Steps:</span>
            <span style={{display:"block", marginBottom:"20px", fontSize:"10pt", lineHeight:"20px"}}>{this.state.autoNextSteps}</span>
          </div>}
          {this.state.tickets.length > 0 && <div id="report-ticket-list" style={{width:"100%"}}>
            <span style={{display:"block", fontWeight:"600", fontSize:"10pt", margin:"15px 0px 5px 0px"}}>
              Bugs<span style={{color:"#519acc"}}>{` - ${this.prioritiesString()}`}</span>
            </span>
            <div style={{display:"grid", gridTemplateColumns:"auto max-content max-content max-content", width:"100%"}}>
            {this.state.tickets.map((ticket, index) => {
              var priority = ticket.ticket_priority_name === "Blocker" ? "Critical" : ticket.ticket_priority_name
              return [
                <div key={`ticket-${ticket.id}-title`} style={{ display:"inline-block", lineHeight:"18px", borderBottom:"solid 1px #e8e8e8", padding:"4px 14px 12px 0px", marginBottom:"10px"}}>
                  <span className="report-ticket-identifier" message={`This will link to ticket ${ticket.ticket_identifier}`} >[{ticket.ticket_identifier}]</span>
                  <span style={{paddingRight:"8px"}}>{` ${ticket.title}`}</span>
                </div>,
                  <span key={`ticket-${ticket.id}-priority`} style={{display:"inline-block", borderBottom:"solid 1px #e8e8e8", padding:"0px 14px 12px 0px", marginBottom:"10px"}}>
                    <span style={{fontWeight:"600"}}>Priority - </span>
                    <span style={{color: priority === "Low" ? "#6eb782" : priority === "Normal" ? "#0066cc" : priority === "High" ? "#ebc34c" : "#ea6159"}}>{priority}</span>
                  </span>,
                  <span key={`ticket-${ticket.id}-status`} style={{display:"inline-block", borderBottom:"solid 1px #e8e8e8", padding:"0px 14px 12px 0px", marginBottom:"10px"}}>
                    <span style={{fontWeight:"600"}}>Status - </span>
                    <span style={{color: "#519acc"}}>{ticket.ticket_status_name}</span>
                  </span>,
                  <span key={`ticket-${ticket.id}-assignee`} style={{display:"inline-block", borderBottom:"solid 1px #e8e8e8", padding:"0px 12px 0px 0px", marginBottom:"10px"}}>
                    <span style={{fontWeight:"600"}}>Assigned To - </span>
                    <span>{ticket.assignee_name}</span>
                  </span>   
            ]
            })}
            </div>
          </div>}
        </div> : 
        <div id="report-heading" style={{width:"100%"}}>
          <div id="report-heading-left">
            {this.state.manualProject ?
              <div style={{borderRadius:"50%", height:"50px", width:"50px", margin:"0px 20px 0px 10px", fontSize:"16pt", color:"white", display:"flex", justifyContent:"center", alignItems:"center", backgroundColor:"#519acc"}}>
                {this.state.manualProject[0].toUpperCase()}
              </div> :
              this.props.data.project.icon_base64?
              <img src={`data:image/png;base64, ${this.props.data.project.icon_base64}`} style={{borderRadius:"50%", height:"50px", width:"50px", margin:"0px 20px 0px 10px"}} />:
              <div style={{borderRadius:"50%", height:"50px", width:"50px", margin:"0px 20px 0px 10px", fontSize:"16pt", color:"white", display:"flex", justifyContent:"center", alignItems:"center", backgroundColor:"#519acc"}}>
                {this.props.data.project.name[0].toUpperCase()}
              </div>
            }
            <div style={{height:"50px"}}>
              <span style={{display:"block", color:"#519acc", fontSize:"20pt"}}>{this.state.manualProject ? this.state.manualProject : this.props.data.project.name}</span>
              <span style={{display:"block", fontSize:"11pt"}}>Report for: 
              <span style={{color:"#519acc"}}> {moment().format("MM/DD/YY")}</span>
              </span>
            </div>
          </div>
          <div id="report-heading-right">
            <div style={{display:"flex", flexDirection:"column", alignItems:"center", position:"relative"}}>
              <img style={{height:"40px", width:"40px", display:"block", marginBottom:"15px"}} src="/Icons_Bugs_Filled.png"/>
              <span style={{display:"block", color:"#519acc", fontSize:"20pt", marginBottom:"5px"}}>
                {this.state.manualBugsFiled}
              </span>
              <span style={{display:"block", fontSize:"10pt"}}>Bugs Filed</span>
            </div>
            {this.state.manualStepsCompleted && <div style={{display:"flex", flexDirection:"column", alignItems:"center", position:"relative"}}>
              <img style={{height:"40px", width:"40px", display:"block", marginBottom:"15px"}} src="/Icons_Test_Cases_Filled.png"/>
              <span style={{display:"block", color:"#519acc", fontSize:"20pt", marginBottom:"5px"}}>
                {this.state.manualStepsCompleted}
              </span>
              <span style={{display:"block", fontSize:"10pt"}}>Steps Completed</span>
            </div>}
            {this.state.manualDevicesTested && <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
              <img style={{height:"40px", width:"40px", display:"block", marginBottom:"15px"}} src="/device_lab_filled.png"/>
              <span style={{display:"block", color:"#519acc", fontSize:"20pt", marginBottom:"5px"}}>
                {this.state.manualDevicesTested}
              </span>
              <span style={{display:"block", fontSize:"10pt"}}>Devices Tested</span>
            </div>}
          </div>
          {this.state.manualNotes && <div id="report-notes" style={{width:"100%", borderBottom:"solid 1px lightgray"}}>
            <span style={{display:"block", fontWeight:"600", fontSize:"10pt", margin:"15px 0px 5px 0px"}}>Notes from the Project Manager:</span>
            <span style={{display:"block", marginBottom:"20px", fontSize:"10pt", lineHeight:"20px"}}>{this.state.manualNotes}</span>
          </div>}
          {this.state.manualNextSteps && <div id="report-next-steps" style={{width:"100%", borderBottom:"solid 1px lightgray"}}>
            <span style={{display:"block", fontWeight:"600", fontSize:"10pt", margin:"15px 0px 5px 0px"}}>Next Steps:</span>
            <span style={{display:"block", marginBottom:"20px", fontSize:"10pt", lineHeight:"20px"}}>{this.state.manualNextSteps}</span>
          </div>}
          {this.state.manualBugLinks.length > 0 && <div id="report-ticket-list" style={{width:"100%"}}>
            <span style={{display:"block", fontWeight:"600", fontSize:"10pt", margin:"15px 0px 5px 0px"}}>
              Bug Links:
            </span>
            <div style={{width:"100%"}}>
            {this.state.manualBugLinks.map((link, index) =>{
              return (
              <span key={index} style={{display:"block", padding:"4px 0px 12px 0px", marginBottom:"10px", borderBottom:"1px solid #e8e8e8"}}><span className="report-bug-link" message={`This will link to bug`}>{link}</span></span>
              )
                
            })}
            </div>
          </div>}
        </div>
        }
        
      </div>}
    </div>
    )
  }

}
export default GenerateTesterReport
