import React from "react";
import axios from "axios";
import ReactGA from "react-ga";
import moment from "moment";
import Swal from "sweetalert2";
import { TrixEditor } from "../common/trix_container.jsx";

ReactGA.initialize(`${process.env.G_ANALYTICS_TAG}`, {
  debug: !process.env.NODE_ENV == "production",
  titleCase: false,
});

class TestStepComments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: [],
      editLoading: false,
      loading: true,
      commentValue: "",
      editCommentValue: "",
      currentCommentEdit: null,
      existingComments: this.props.data.existingComments,
    };
    this.commentSaveButton = React.createRef();
    this.commentAddButton = React.createRef();
    this.commentCancelButton = React.createRef();
    this.commentText = React.createRef();
    this.commentEditText = React.createRef();
    this.commentEditLoaderOverlay = React.createRef();
    this.handleEditorReady = this.handleEditorReady.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleEditChange = this.handleEditChange.bind(this);
    this.handleEditEditorReady = this.handleEditEditorReady.bind(this);
    this.handleCancelComment = this.handleCancelComment.bind(this);
    this.editor = React.createRef();
  }

  componentDidMount() {
    document.getElementById("test-step-comments-container-title").focus();
    if (this.commentAddButton && this.commentAddButton.current) {
      this.commentAddButton.current.disabled = true;
    }
    const elem = document.querySelector(".new-modal-open");
    if (this.editor && this.editor.current) {
      this.handleEditorReady();
    }
    elem.style.top = "5%";
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
    if (this.state.existingComments) {
      axios
        .get(`/get_test_step_comments/${this.props.data.testStepId}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          params: {
            device: this.props.data.device,
          },
        })
        .then((res) => {
          const comments = res.data;
          this.setState({
            comments,
            loading: false,
          });
        })
        .catch((err) => console.error(err));
    } else {
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    const elem = document.querySelector(".new-modal-open");
    elem.style.top = "12%";
    const previousFocusElem = document.querySelector(
      this.props.data.currentResultSelector
    );
    if (previousFocusElem) {
      previousFocusElem.focus();
    }
  }

  handleAddComment = () => {
    if (this.commentAddButton && this.commentAddButton.current) {
      this.commentAddButton.current.disabled = true;
    }

    const { commentValue } = this.state;
    const { data } = this.props;

    axios
      .post("/add_test_step_comment", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        content: commentValue,
        id: data.testStepId,
        test_pass_id: data.testPassId,
        device: data.device,
      })
      .then((res) => {
        M.toast({
          html: '<p role="alert">Comment added</p>',
          displayLength: 3000,
          classes: "green",
        });

        const newComment = { ...res.data, comment: res.data.comment };
        this.setState((prevState) => ({
          comments: [...prevState.comments, newComment],
          existingComments: true,
          currentCommentEdit: null,
          editLoading: false,
        }));

        if (this.editor) {
          this.editor.loadHTML("");
        }

        ReactGA.event({
          category: "Test Step Comment",
          action: "Test Step Comment Created",
        });
      })
      .catch((err) => {
        ReactGA.event({
          category: "Test Step Comment",
          action: "Test Step Comment Comment Error",
        });
        console.error(err);
      });
  };

  handleSaveComment = (e) => {
    const self = this;
    if (this.commentSaveButton && this.commentSaveButton.current) {
      this.commentSaveButton.current.disabled = true;
    }
    if (this.commentCancelButton && this.commentSaveButton.current) {
      this.commentCancelButton.current.disabled = true;
    }
    if (
      this.commentEditLoaderOverlay &&
      this.commentEditLoaderOverlay.current
    ) {
      this.setState({ editLoading: true }, () => {
        requestAnimationFrame(() => {
          this.commentEditLoaderOverlay.current.style.opacity = "1";
        });
      });
    }
    const { commentId } = e.target.dataset;
    let editText = "";
    if (this.commentEditText && this.commentEditText.current) {
      editText = this.commentEditText.current.value;
    }
    axios
      .patch(`/update_test_step_comment/${commentId}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        comment_text: editText,
        content: this.state.editCommentValue,
      })
      .then((res) => {
        M.toast({
          html: '<span role="alert">Comment updated</span>',
          displayLength: 3000,
          classes: "green",
        });
        // this.props.modalAction(false, "", {})
        const { comments } = this.state;
        for (let x = 0; x < comments.length; x++) {
          if (comments[x].id === res.data.id) {
            comments.splice(x, 1, { ...res.data, comment: res.data.comment });
            break;
          }
        }
        if (
          this.commentText &&
          this.commentText.current &&
          this.commentAddButton &&
          this.commentAddButton.current &&
          this.commentText.current.value !== ""
        ) {
          this.commentAddButton.current.disabled = false;
        }
        if (
          self.props.data.modalRef &&
          self.props.data.modalRef.current &&
          self.props.data.modalRef.current.cache &&
          Object.keys(self.props.data.modalRef.current.cache).length !== 0
        ) {
          const { cache } = self.props.data.modalRef.current;
          if (Object.values.length >= 1) {
            let innerComments = [];
            if (
              this.props.data.device != "" &&
              cache[`${self.props.data.testStepId}-${self.props.data.device}`]
            ) {
              innerComments = Object.values(
                cache[`${self.props.data.testStepId}-${self.props.data.device}`]
              );
            } else if (cache[`${self.props.data.testStepId}-`]) {
              innerComments = Object.values(
                cache[`${self.props.data.testStepId}-`]
              );
            }
            for (let index = 0; index < innerComments.length; index++) {
              const element = innerComments[index];
              if (comments[index]) {
                element.comment = comments[index].comment;
              }
            }
            self.props.data.modalRef.current.cache = cache;
          }
        }
        this.setState({
          comments,
          currentCommentEdit: null,
          editLoading: false,
          editCommentValue: "",
        });
        ReactGA.event({
          category: "Test Step Comment",
          action: "Test Step Comment Updated",
        });
      })
      .catch((err) => {
        M.toast({
          html: '<span role="alert">Error updating comment</span>',
          displayLength: 3000,
          classes: "red",
        });
        ReactGA.event({
          category: "Test Step Comment",
          action: "Test Step Comment Update Error",
        });
        console.error(err);
      });
  };

  handleDeleteComment = (e) => {
    const { commentId } = e.target.dataset;
    Swal.fire({
      title: "Delete Comment",
      text: "That you want to delete this Comment?",
      type: "warning",
      showCancelButton: true,
      confirmButtonAriaLabel: "Yes",
      cancelButtonAriaLabel: "cancel",
      reverseButtons: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        axios
          .delete(`/delete_test_step_comment/${commentId}`, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            data: {
              test_pass_id: this.props.data.testPassId,
            },
          })
          .then((res) => {
            M.toast({
              html: '<span role="alert">Comment deleted</span>',
              displayLength: 3000,
              classes: "green",
            });
            let updatedComments = this.state.comments;
            updatedComments = updatedComments.filter(
              (comment) => comment.id !== res.data.id
            );
            this.setState({ comments: updatedComments });
            ReactGA.event({
              category: "Test Step Comment",
              action: "Test Step Comment Deleted",
            });
          })
          .catch((err) => console.error(err));
      } else if (result.dismiss) {
      }
    });
  };

  closeModal = () => {
    this.props.modalAction(false, "", {});
  };

  handleEditComment = (e) => {
    const self = this;
    if (self.commentAddButton && self.commentAddButton.current) {
      self.commentAddButton.current.disabled = true;
      self.commentAddButton.current.classList.remove(
        "new-design-button-enabled"
      );
      self.commentAddButton.current.classList.add("new-design-button-disabled");
      const { commentId } = e.target.dataset;
      self.setState({
        currentCommentEdit: parseInt(commentId),
      });
    }
  };

  handleCancelComment() {
    if (this.commentAddButton && this.commentAddButton.current) {
      if (this.state.editCommentValue !== "") {
        this.commentAddButton.current.disabled = false;
      }
      this.setState({
        currentCommentEdit: null,
      });
    }
  }

  handleEditorReady(editor) {
    // this is a reference back to the editor if you want to
    // do editing programatically
    this.editor = editor;
  }

  handleEditEditorReady(editor) {
    // this is a reference back to the editor if you want to
    // do editing programatically
    const comment = this.state.comments.find(
      (x) => x.id === this.state.currentCommentEdit
    );
    this.editor = editor;
    editor.insertHTML(comment.content);
  }

  handleChange(html, text) {
    // html is the new html content
    // text is the new text content
    this.setState({ commentValue: html });
  }

  handleEditChange(html, text) {
    // html is the new html content
    // text is the new text content
    this.setState({ editCommentValue: html });
  }

  render() {
    const editing = this.state.currentCommentEdit !== null;
    return (
      <div
        role="dialog"
        aria-label="Test Case Comments Modal"
        className="modal-content"
      >
        <div id="test-step-comments-container">
          <h2
            style={{ outline: "none" }}
            tabIndex={0}
            className="small-title"
            id="test-step-comments-container-title"
          >
            TC {this.props.data.testCaseNumber}
            {this.props.data.device ? ` ${this.props.data.device}` : ""}{" "}
            Comments
          </h2>
          {this.state.existingComments && (
            <div
              className="new-scrollbar"
              id="test-step-existing-comments-container"
            >
              {this.state.loading ? (
                <div id="test-step-comments-loader" />
              ) : (
                <div>
                  {this.state.comments.map((comment, index) => {
                    if (comment.id === this.state.currentCommentEdit) {
                      return (
                        <div
                          key={comment.id}
                          id="test-step-edit-comment-container"
                          style={{ position: "relative", marginBottom: "12px" }}
                        >
                          {this.state.editLoading && (
                            <div
                              ref={this.commentEditLoaderOverlay}
                              style={{
                                position: "absolute",
                                opacity: "0",
                                transition: "opacity 0.3s",
                                transitionDelay: "0.1s",
                                left: "0px",
                                top: "0px",
                                width: "100%",
                                height: "100%",
                                backgroundColor: "#0000002b",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div id="test-step-comment-edit-loader" />
                            </div>
                          )}
                          <h4
                            style={{ pointerEvents: "none" }}
                            className="test-step-comment-title"
                          >
                            {comment.name} posted a comment -{" "}
                            {moment(comment.created_at).format("lll")}
                          </h4>
                          <TrixEditor
                            onChange={this.handleEditChange}
                            className="trix-content"
                            value={this.state.editCommentValue}
                            onEditorReady={this.handleEditEditorReady}
                          />
                          <button
                            ref={this.commentSaveButton}
                            style={{ marginTop: "10px" }}
                            className="add-test-step-comment-button common-button-submit"
                            onClick={this.handleSaveComment}
                            data-comment-id={comment.id}
                          >
                            Save
                          </button>
                          <button
                            ref={this.commentCancelButton}
                            style={{ marginLeft: "10px" }}
                            className="add-test-step-comment-button common-button-cancel"
                            onClick={this.handleCancelComment}
                          >
                            Cancel
                          </button>
                        </div>
                      );
                    }
                    return (
                      <div
                        key={index}
                        className="test-step-single-comment-container"
                      >
                        <p className="test-step-comment-title">
                          <span role="text">
                            {comment.name} posted a comment -{" "}
                            {moment(comment.created_at).format("lll")}
                          </span>
                          {this.props.data.currentUserId ===
                            comment.user_id && (
                            <button
                              aria-label={`Edit your comment from ${moment(
                                comment.created_at
                              ).format("lll")}`}
                              className="i-btn-wrapper"
                              data-comment-id={comment.id}
                              onClick={this.handleEditComment}
                              onMouseDown={this.props.data.onMouseDown}
                              onBlur={this.props.data.onBlur}
                            >
                              <i
                                aria-hidden
                                data-comment-id={comment.id}
                                className="material-icons test-step-comment-delete-icon"
                                style={{
                                  display: "inline-block",
                                  pointer: editing ? "default" : "pointer",
                                  opacity: "0",
                                  pointerEvents: editing ? "none" : "all",
                                  color: editing ? "#b9b9b9" : "#242424",
                                  marginLeft: "6px",
                                }}
                              >
                                edit
                              </i>
                            </button>
                          )}
                          <button
                            className="i-btn-wrapper"
                            data-comment-id={comment.id}
                            onClick={this.handleDeleteComment}
                            aria-label={`Delete ${
                              this.props.data.currentUserId === comment.user_id
                                ? "your"
                                : `${comment.name}'s'`
                            } comment from ${moment(comment.created_at).format(
                              "lll"
                            )}`}
                          >
                            <i
                              aria-hidden="true"
                              data-comment-id={comment.id}
                              className="material-icons test-step-comment-delete-icon"
                              style={{
                                display: "inline-block",
                                opacity: "0",
                                marginLeft: "4px",
                              }}
                            >
                              delete
                            </i>
                          </button>
                        </p>
                        <p
                          className="test-step-comment-text"
                          dangerouslySetInnerHTML={{
                            __html: comment.content,
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}
          <div id="test-step-new-comment-container">
            <h2
              className="small-title"
              id="test-step-new-comment-container-title"
            >
              New Comment
            </h2>
            <TrixEditor
              onChange={this.handleChange}
              className="trix-content"
              value={this.state.commentValue}
              placeholder="Enter new comment"
              onEditorReady={this.handleEditorReady}
            />
            <button
              ref={this.commentAddButton}
              disabled={this.state.commentValue == ""}
              style={{ width: "115px", marginTop: "20px" }}
              className="btn"
              onClick={this.handleAddComment}
            >
              Add Comment
            </button>
          </div>
        </div>
        <button
          aria-label="close comment modal"
          className="icon-close-btn"
          style={{
            display: "block",
            position: "absolute",
            top: "10px",
            right: "15px",
          }}
          onClick={this.closeModal}
        />
      </div>
    );
  }
}
export default TestStepComments;
