import React, { useMemo, useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import queryString from "query-string";
import useDebounce from "@hooks/useDebounce";
import useDeviceLab from "@hooks/data/useDeviceLab";

const DeviceFuzzySearch = () => {
  const {
    filters,
    PARAM_KEYS: { SEARCH, PAGE },
  } = useDeviceLab();

  const [searchTerm, setSearchTerm] = useState(filters.search);

  const debouncedSearch = useDebounce(searchTerm, 500);

  const query = useMemo(() => {
    return filters.query || {};
  }, [filters]);

  const currentUrlSearch = useMemo(() => {
    return filters.search || "";
  }, [filters]);

  const updateSearch = useCallback((e) => {
    setSearchTerm(e.target.value);
  }, []);

  const clearSearch = useCallback(() => {
    setSearchTerm("");
  }, []);

  const inputBlur = useCallback((e) => {
    e.target.setAttribute("aria-label", "Search");
  }, []);

  const newSearch = useCallback(
    (s) => {
      const nextQuery = { ...query };
      delete nextQuery[PAGE];
      nextQuery[SEARCH] = s;
      if (!s || s.length < 3) {
        delete nextQuery[SEARCH];
      }
      const nextQueryString = queryString.stringify(nextQuery, {
        arrayFormat: "bracket",
      });
      window.history.replaceState({}, "", `?${nextQueryString}`);
    },
    [query, SEARCH, PAGE]
  );

  useEffect(() => {
    newSearch(debouncedSearch);
  }, [debouncedSearch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSearchTerm(currentUrlSearch);
  }, [currentUrlSearch]);

  return (
    <FuzzySearchContainer>
      <InputContainer>
        <InputIcon aria-hidden="true" className="material-icons prefix">
          search
        </InputIcon>
        <SearchInput
          className="browser-default roundedSearchInput"
          type="text"
          onChange={updateSearch}
          placeholder=" "
          aria-live="polite"
          value={searchTerm}
          aria-label="Search"
          onBlur={inputBlur}
        />
        {searchTerm !== "" && (
          <button
            type="button"
            onClick={clearSearch}
            className="link-btn material-icons grey-text"
          >
            close
          </button>
        )}
      </InputContainer>
    </FuzzySearchContainer>
  );
};

const SearchInput = styled.input``;

const InputIcon = styled.i`
  position: absolute;
  pointer-events: none;
  left: 6px;
  color: #ababab;
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FuzzySearchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export default DeviceFuzzySearch;
