import React, { useState, useRef, useEffect } from "react";
import DashboardTimeSelector from "../devices/dashboard_time_selector.jsx";
import axios from "axios";
import moment from "moment";
import GeneralStats from "./general_stats";
import BugsIcon from "../icons/bugs_icon.jsx";

function BugChart(props) {
  const [bugs, setBugs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [chartTransition, setChartTransition] = useState(false);
  const [pickerTransition, setPickerTransition] = useState(false);
  const [graphLineIncrement, setGraphLineIncrement] = useState(5);
  const [lineCount, setLineCount] = useState(4);
  const [startDate, setStartDate] = useState(
    moment(new Date()).startOf("week")
  );
  const [endDate, setEndDate] = useState(moment(new Date()).endOf("week"));
  const [columnData, setColumnData] = useState([
    [0, 0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
  ]);
  const [hoverIndex, setHoverIndex] = useState(null);
  const [sliceIndex, setSliceIndex] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const mainLabels = ["Platform", "Priority", "Status"];
  const columnColors = [
    ["#015391", "#007cc2", "#02b3ef", "#6ed1fe", "#8dcfeb"],
    ["#009900", "#0066cc", "#ff9900", "#ff0000"],
    ["#E14D57", "#EC932F", "#13CD9D", "#5290E9"],
  ];
  const columnLabels = [
    ["Multiple", "Windows", "macOS", "iOS", "Android"],
    ["Low", "Normal", "High", "Critical"],
    ["Open", "In Progress", "Fixed", "Closed"],
  ];
  const containerRef = useRef(null);
  const floatyRef = useRef(null);
  const [scope, setScope] = useState("last30");

  useEffect(() => {
    setScope("last30");
    setPickerTransition(true);
    setTimeout(() => {
      getData("last30", true);
    }, 300);
  }, [props.projectId]);

  function timeHandler(time) {
    setScope(time);
    setPickerTransition(true);
    setTimeout(() => {
      getData(time, true);
    }, 300);
  }

  function dateHandler(start, end) {
    setScope("custom");
    setStartDate(start);
    setEndDate(end);
    setPickerTransition(true);
    setTimeout(() => {
      getData("custom", true, start, end);
    }, 300);
  }

  function handleColumnMouseEnter(e) {
    if (
      floatyRef.current &&
      floatyRef.current.style.opacity !== "1" &&
      e.target.id &&
      e.target.id.includes("slice")
    ) {
      var id = e.target.id.split("-")[2];
      setHoverIndex(parseInt(id));
      floatyRef.current.style.opacity = "1";
    }
  }

  function handleColumnMouseLeave() {
    if (floatyRef.current) {
      floatyRef.current.style.opacity = "0";
    }
    setSliceIndex(null);
  }

  function handleSliceMouseEnter(e) {
    if (
      floatyRef.current &&
      containerRef.current &&
      e.target.id &&
      e.target.id.includes("slice")
    ) {
      var elem = e.target;
      var rect = elem.getBoundingClientRect();
      var containerRect = containerRef.current.getBoundingClientRect();
      var id = e.target.id.split("-")[2];
      var index = e.target.id.split("-")[3];
      setSliceIndex(parseInt(index));
      var listLength = columnData[parseInt(id)].filter((d) => d !== 0).length;
      var x =
        rect.x -
        containerRect.x +
        (id === "0" || id === "1" ? elem.clientWidth + 10 : -210);
      var y =
        rect.y -
        containerRect.y -
        (listLength * 28 + 20) / 2 +
        elem.clientHeight / 2;
      floatyRef.current.style.transform = `translate(${x}px, ${y}px)`;
    }
  }

  function getData(time, timePicker, start, end) {
    setLoading(true);
    var url = !start
      ? `/projects/${props.projectId}/project_bugs.json?scope=${time}`
      : `/projects/${props.projectId}/project_bugs.json?scope=${time}&start_date=${start}&end_date=${end}`;
    axios
      .get(url)
      .then((res) => {
        setBugs(res.data);
        var tempIncrement = 1;
        while (res.data.length / 2 > tempIncrement * 2) {
          tempIncrement *= 2;
          if (tempIncrement === 4) {
            tempIncrement = 5;
          }
        }
        if (res.data.length / 2 > tempIncrement * 1.5) {
          setLineCount(5);
        } else {
          setLineCount(4);
        }
        var c1Data = [0, 0, 0, 0, 0];
        var c2Data = [0, 0, 0, 0];
        var c3Data = [0, 0, 0, 0];
        res.data.forEach((b) => {
          switch (b.ticket_status_id) {
            case 1:
            case 6:
              c3Data[0] += 1;
              break;
            case 4:
              c3Data[1] += 1;
              break;
            case 3:
            case 5:
              c3Data[2] += 1;
              break;
            case 2:
              c3Data[3] += 1;
          }

          switch (b.ticket_priority_id) {
            case 4:
              c2Data[0] += 1;
              break;
            case 3:
              c2Data[1] += 1;
              break;
            case 2:
              c2Data[2] += 1;
              break;
            case 1:
              c2Data[3] += 1;
          }
          if (b.devices.length === 0) {
            c1Data[0] += 1;
          } else if ([...new Set(b.devices)].length === 1) {
            switch (b.devices[0]) {
              case 1:
                c1Data[1] += 1;
                break;
              case 2:
                c1Data[2] += 1;
                break;
              case 3:
                c1Data[3] += 1;
                break;
              case 4:
                c1Data[4] += 1;
                break;
              default:
                c1Data[0] += 1;
            }
          } else {
            c1Data[0] += 1;
          }
        });
        setColumnData([c1Data, c2Data, c3Data]);
        setGraphLineIncrement(tempIncrement);
        setLoading(false);
        if (timePicker) {
          setPickerTransition(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        if (timePicker) {
          setPickerTransition(false);
        }
      });
  }

  function subColumnTransition(e) {
    if (selectedColumn === null) {
      setChartTransition(true);
      var value = e.target.value;
      setTimeout(() => {
        var maxNum = Math.max(...columnData[parseInt(value)]);
        var tempIncrement = 1;
        while (maxNum / 2 > tempIncrement * 2) {
          tempIncrement *= 2;
          if (tempIncrement === 4) {
            tempIncrement = 5;
          }
        }
        if (maxNum / 2 > tempIncrement * 1.5) {
          setLineCount(5);
        } else {
          setLineCount(4);
        }
        setSelectedColumn(parseInt(value));
        setGraphLineIncrement(tempIncrement);
        setTimeout(() => {
          setChartTransition(false);
        }, 300);
      }, 300);
    } else {
      setChartTransition(true);
      setTimeout(() => {
        var tempIncrement = 1;
        while (bugs.length / 2 > tempIncrement * 2) {
          tempIncrement *= 2;
          if (tempIncrement === 4) {
            tempIncrement = 5;
          }
        }
        if (bugs.length / 2 > tempIncrement * 1.5) {
          setLineCount(5);
        } else {
          setLineCount(4);
        }
        setSelectedColumn(null);
        setGraphLineIncrement(tempIncrement);
        setTimeout(() => {
          setChartTransition(false);
        }, 300);
      }, 300);
    }
  }

  const renderColumn = (index, label) => {
    var topNumber = graphLineIncrement * (lineCount - 1);
    var height =
      selectedColumn == null
        ? (bugs.length / topNumber) * 100
        : (columnData[selectedColumn][index] / topNumber) * 100;
    return (
      <div
        className="bug-chart-column"
        style={{ height: lineCount == 4 ? "75%" : "72%" }}
      >
        <div
          onMouseEnter={handleColumnMouseEnter}
          onMouseLeave={handleColumnMouseLeave}
          id={`bug-column-${index}`}
          style={{ height: `${height}%` }}
        >
          {(selectedColumn === null) & (index < 3) ? (
            columnData[index].map((d, i) => {
              return (
                <div
                  onMouseEnter={handleSliceMouseEnter}
                  key={`column-slice-${index}-${i}`}
                  id={`column-slice-${index}-${i}`}
                  style={{
                    width: "100%",
                    flex: "1",
                    transition: "max-height 0.3s",
                    maxHeight: `${
                      bugs.length > 0 && !chartTransition && !pickerTransition
                        ? (d / bugs.length) * 100
                        : 0.01
                    }%`,
                    backgroundColor: columnColors[index][i],
                  }}
                />
              );
            })
          ) : (
            <div
              style={{
                width: "100%",
                flex: "1",
                transition: "max-height 0.3s",
                maxHeight: `${
                  selectedColumn !== null &&
                  columnData[selectedColumn][index] !== 0 &&
                  !chartTransition &&
                  !pickerTransition
                    ? 100
                    : 0.01
                }%`,
                backgroundColor:
                  selectedColumn !== null
                    ? columnColors[selectedColumn][index]
                    : "#519acc",
              }}
            />
          )}
        </div>
        <button
          onClick={subColumnTransition}
          value={index}
          style={{
            backgroundColor:
              selectedColumn == null
                ? "white"
                : columnColors[selectedColumn][index],
            opacity: bugs.length > 0 ? "1" : "0",
            pointerEvents:
              bugs.length > 0 &&
              selectedColumn === null &&
              !chartTransition &&
              !pickerTransition
                ? "all"
                : "none",
            borderColor:
              selectedColumn == null
                ? "#519acc"
                : columnColors[selectedColumn][index],
          }}
        >
          <span
            style={{
              color: selectedColumn == null ? "#519acc" : "white",
              pointerEvents: "none",
              transition: "opacity 0.3s",
              opacity: chartTransition ? "0" : "1",
            }}
          >
            {selectedColumn == null
              ? label
              : columnLabels[selectedColumn][index]}
          </span>
        </button>
      </div>
    );
  };

  return (
    <div style={{ display: "flex", height: "100%" }}>
      {props.bugCount > 0 ? (
        <div
          ref={containerRef}
          id="bug-chart-container"
          style={{
            width: "100%",
            position: "relative",
            padding: "10px 30px 10px 10px",
            borderRadius: "5px",
          }}
        >
          <div
            className={hoverIndex > 1 ? "arrow-right" : ""}
            ref={floatyRef}
            id="bug-chart-floaty"
          >
            {hoverIndex !== null
              ? columnData[hoverIndex].map((d, i) => {
                  return (
                    <div
                      id={`slice-label-${hoverIndex}-${i}`}
                      style={{
                        display: d === 0 ? "none" : "flex",
                        padding: "0 5px",
                        borderRadius: "4px",
                        justifyContent: "space-between",
                        transition: "background-color 0.3s",
                        alignItems: "center",
                        backgroundColor:
                          i === sliceIndex ? "#519acc" : "transparent",
                      }}
                      key={i}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          style={{
                            width: "13px",
                            height: "13px",
                            borderRadius: "50%",
                            boxShadow: "#8e8e8e 0px 1px 4px 0px",
                            border: "solid 2px white",
                            backgroundColor: columnColors[hoverIndex][i],
                          }}
                        />
                        <span
                          style={{
                            color: i === sliceIndex ? "white" : "#4d4d4d",
                            paddingLeft: "10px",
                            transition: "color 0.3s",
                          }}
                        >
                          {columnLabels[hoverIndex][i]}
                        </span>
                      </div>
                      <span
                        style={{
                          color: i === sliceIndex ? "white" : "#4d4d4d",
                        }}
                      >
                        {d}
                      </span>
                    </div>
                  );
                })
              : ""}
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              transition: "flex",
              alignItems: "center",
              height: "70px",
              position: "relative",
            }}
          >
            <h2
              className="performance-large-title"
              style={{ padding: "0 20px" }}
            >
              Bug Breakdown
            </h2>
            <DashboardTimeSelector
              key={2}
              handler={timeHandler}
              customHandler={dateHandler}
              startDate={startDate}
              endDate={endDate}
              scope={scope}
              containerStyle={{
                position: "relative",
                left: "0px",
                top: "0",
                marginRight: "20px",
              }}
            />
            <button
              onClick={subColumnTransition}
              style={{
                width: "fit-content",
                cursor: "pointer",
                border: "none",
                padding: "5px 14px",
                backgroundColor: "#519acc",
                borderRadius: "30px",
                opacity: selectedColumn === null || chartTransition ? "0" : "1",
                transition: "opacity 0.3s",
              }}
            >
              {selectedColumn !== null && (
                <span
                  style={{
                    color: "white",
                    fontFamily: "arial",
                    fontSize: "14px",
                  }}
                >
                  {mainLabels[selectedColumn]}
                  <span
                    style={{
                      paddingLeft: "8px",
                      fontFamily: "arial",
                      position: "relative",
                      bottom: "1px",
                    }}
                  >
                    x
                  </span>
                </span>
              )}
            </button>
          </div>
          <div
            style={{
              height: "calc(100% - 80px)",
              position: "relative",
              width: "100%",
              paddingLeft: "56px",
              display: "flex",
              flexDirection: "column-reverse",
              flexWrap: "nowrap",
              overflow: "hidden",
            }}
          >
            {Array(5)
              .fill(0)
              .map((_, i) => {
                return (
                  <div
                    style={{
                      position: "relative",
                      borderTop: "solid 1px lightgray",
                      height: "18%",
                      width: "100%",
                      transition: "transform 0.3s",
                      transform: `translateY(${
                        lineCount === 5 ? 0 : i * -40
                      }%)`,
                    }}
                    key={i}
                  >
                    <span
                      style={{
                        position: "absolute",
                        top: "-15px",
                        opacity:
                          bugs.length > 0 &&
                          !chartTransition &&
                          !pickerTransition
                            ? "1"
                            : "0",
                        transition: "opacity 0.4s",
                        color: "#bfbfbf",
                        left: "-65px",
                        textAlign: "right",
                        display: "block",
                        width: "40px",
                        fontFamily: "arial",
                        fontSize: "18px",
                      }}
                    >
                      {graphLineIncrement * i}
                    </span>
                  </div>
                );
              })}
            <div
              id="bug-column-container"
              style={{
                width: "calc(100% - 60px)",
                height: "100%",
                position: "absolute",
                left: "60px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "90%",
                  height: "100%",
                  overflow: "hidden",
                  position: "relative",
                }}
              >
                {loading && (
                  <div
                    style={{
                      height: "100px",
                      width: "100px",
                      position: "absolute",
                      top: "30%",
                      left: 0,
                      right: 0,
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <div id="test-step-bugs-loader" />
                  </div>
                )}
                <h2
                  style={{
                    position: "absolute",
                    top: "45%",
                    transform: "translateY(-50%)",
                    left: "calc(50% - 170px)",
                    color: "#bfbfbf",
                    opacity: bugs.length > 0 || loading ? "0" : "1",
                    pointerEvents: "none",
                    transition: "opacity 0.3s",
                  }}
                >
                  No bugs found for this time range
                </h2>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "nowrap",
                    height: "100%",
                    width: "100%",
                    justifyContent: "space-between",
                    position: "relative",
                    bottom: "18%",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      minWidth:
                        selectedColumn == null
                          ? "33.33%"
                          : selectedColumn !== 0
                          ? "25%"
                          : "20%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-end",
                      transition: "min-width 0.3s",
                    }}
                  >
                    {renderColumn(0, "Platform")}
                  </div>
                  <div
                    style={{
                      flex: "1",
                      minWidth:
                        selectedColumn == null
                          ? "33.33%"
                          : selectedColumn !== 0
                          ? "25%"
                          : "20%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-end",
                      transition: "min-width 0.3s",
                    }}
                  >
                    {renderColumn(1, "Priority")}
                  </div>
                  <div
                    style={{
                      flex: "1",
                      minWidth:
                        selectedColumn == null
                          ? "33.33%"
                          : selectedColumn !== 0
                          ? "25%"
                          : "20%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-end",
                      transition: "min-width 0.3s",
                    }}
                  >
                    {renderColumn(2, "Status")}
                  </div>
                  <div
                    style={{
                      flex: "1",
                      minWidth:
                        selectedColumn == null
                          ? "33.33%"
                          : selectedColumn !== 0
                          ? "25%"
                          : "20%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-end",
                      transition: "min-width 0.3s",
                    }}
                  >
                    {renderColumn(3, "")}
                  </div>
                  <div
                    style={{
                      flex: "1",
                      minWidth:
                        selectedColumn == null
                          ? "33.33%"
                          : selectedColumn !== 0
                          ? "25%"
                          : "20%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-end",
                      transition: "min-width 0.3s",
                    }}
                  >
                    {renderColumn(4, "")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          id="bug-chart-container"
          style={{
            width: "100%",
            borderRadius: "5px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "60px",
              height: "60px",
              borderRadius: "50%",
              boxShadow: "0px 1px 2px 1px lightgray",
              backgroundColor: "#519acc",
              overflow: "hidden",
              display: "flex",
              flexWrap: "nowrap",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <BugsIcon width={44} height={44} />
          </div>
          <h2 style={{ margin: "18px 0px" }}>No Bugs Found</h2>
          <p
            style={{
              maxWidth: "420px",
              textAlign: "center",
              lineHeight: "22px",
              marginBottom: "20px",
            }}
          >
            Start tracking bug workflow progress by filling your first bug using
            the button bellow
          </p>
          <a
            style={{
              height: "48px",
              lineHeight: "48px",
              textAlign: "center",
              width: "150px",
            }}
            href={`/projects/${props.projectId}/bugs`}
            className="common-button-submit"
          >
            Navigate to Bugs
          </a>
        </div>
      )}
      <div style={{ width: "350px", height: "100%" }}>
        <GeneralStats
          projectId={props.projectId}
          scope={scope}
          startDate={moment(startDate).format("DD/MM/YYYY")}
          endDate={moment(endDate).format("DD/MM/YYYY")}
        />
      </div>
    </div>
  );
}

export default BugChart;
