import React from "react";
import ReactModalV2 from "../modals/react_modal_v2";
import BasicSelect from "../common/basic_select";

const modals = {};

const cardButtonsObj = {
  ios: { label: "iOS", value: "ios" },
  android: { label: "Android", value: "android" },
  desktop: { label: "Desktop", value: "desktop" },
  arvr: { label: "AR/VR", value: "arvr" },
};
class ClientDeviceLab extends React.Component {
  constructor(props) {
    super(props);
    const devices = Object.entries(this.props.all_devices);
    devices.reverse();
    // devices = devices.map((g)=>[g[0], [...g[1]].sort((a,b)=>a.device_name.toLowerCase() > b.device_name.toLowerCase() ? 1 : a.device_name.toLowerCase() < b.device_name.toLowerCase() ? -1 : 0)])
    this.state = {
      modalOpen: false,
      currentModal: "",
      modalData: {},
      search: "",
      devices,
      selectedFilter: { label: "All", value: "all" },
      descending: true,
    };
    this.ariaCustomStatusRef = React.createRef();
    this.searchTimer = null;
    this.inputRef = React.createRef();
  }

  setModal = (bool, page, data) => {
    this.setState({
      modalOpen: bool,
      currentModal: modals[page],
      modalData: data,
    });
  };

  handleSearch = (e) => {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
      this.searchTimer = null;
    }
    if (e.target.value) {
      const { value } = e.target;
      this.searchTimer = setTimeout(() => {
        this.setState({ search: value });
      }, 300);
    } else {
      this.setState({ search: "" });
    }
  };

  handleSort = () => {
    const { devices } = this.state;
    devices.reverse();
    this.setState({
      devices,
      descending: !this.state.descending,
    });
  };

  clearInput = () => {
    this.setState({ search: "" });
    if (this.inputRef.current) {
      this.inputRef.current.value = "";
    }
  };

  handleFilterChange = (e) => {
    this.setState({ selectedFilter: e });
  };

  handleCardClick = (e) => {
    const { cardKey } = e.target.dataset;
    this.setState({ selectedFilter: cardButtonsObj[cardKey] });
  };

  render() {
    const totalDevices =
      this.props.ios_device_count +
      this.props.android_device_count +
      this.props.desktop_device_count +
      this.props.arvr_device_count;
    let searchCount = 0;
    let deviceGroups = this.state.devices;
    if (this.state.selectedFilter.value !== "all") {
      deviceGroups = deviceGroups.map((g) => [
        g[0],
        g[1].filter((d) => {
          switch (this.state.selectedFilter.value) {
            case "ios":
              return d.device_platform === "iOS";
            case "android":
              return d.device_platform === "Android";
            case "desktop":
              return !!(
                d.device_type === "Laptop" || d.device_type === "Desktop"
              );
            case "arvr":
              return d.device_type === "Wearable";
          }
        }),
      ]);
    }
    deviceGroups = deviceGroups.map((g) => [
      g[0],
      g[1].filter((d) => {
        if (!this.state.search) {
          searchCount += 1;
          return true;
        }
        if (
          `${d.device_name} ${d.device_os}`
            .toLowerCase()
            .includes(this.state.search.toLowerCase())
        ) {
          searchCount += 1;
          return true;
        }
        return false;
      }),
    ]);
    return (
      <div>
        <div
          ref={this.ariaCustomStatusRef}
          style={{
            position: "fixed",
            top: "-200px",
            left: "-200px",
            pointerEvents: "none",
            height: "0px",
            width: "0px",
            overflow: "hidden",
          }}
          data-number="0"
          role="status"
          aria-live="assertive"
          id="aria-custom-status"
        />
        <ReactModalV2
          isShowing={this.state.modalOpen}
          page={this.state.currentModal}
          data={this.state.modalData}
          modalAction={this.setModal}
        />
        <span
          id="main-content"
          tabIndex={0}
          className="large-title"
          role="heading"
          style={{
            display: "block",
            outline: "none",
            backgroundColor: "white",
            position: "relative",
            maxWidth: `calc(100% - ${100}px)`,
            marginLeft: "auto",
            willChange: "max-width",
            transition: "max-width 0.666s ease-in-out 0s",
            padding: "20px 0px 20px 20px",
            zIndex: "10",
            marginTop: "-5px",
            borderBottom: "solid 1px lightgray",
          }}
        >
          <img
            aria-hidden
            style={{
              display: "inline-block",
              height: "50px",
              marginRight: "10px",
              verticalAlign: "-16px",
            }}
            src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Device_Lab_Filled.svg"
          />
          Device Lab
        </span>
        <div
          id="community-container"
          style={{
            width: "100%",
            padding: "0px 0px 20px 20px",
            maxWidth: `calc(100% - ${100}px)`,
            willChange: "max-width",
            transition: "max-width 0.666s ease-in-out 0s",
            whiteSpace: "nowrap",
            marginLeft: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "20%",
                padding: "23px 0 20px",
                maxWidth: "220px",
                minWidth: "145px",
                height: "160px",
                textAlign: "center",
              }}
            >
              <img
                style={{ display: "block", height: "100%", margin: "0 auto" }}
                src="https://plusqa-assets.sfo2.digitaloceanspaces.com/bc-images/assets/scientist_tess.png"
              />
            </div>
            <div
              className="client-device-lab-card"
              onClick={this.handleCardClick}
              data-card-key="ios"
              style={{
                width: "calc(20% - 10px)",
                zIndex: "1",
                padding: "23px 0 20px",
                position: "relative",
                maxWidth: "220px",
                minWidth: "145px",
                height: "110px",
                color: "white",
                fontFamily: "manrope",
                borderRadius: "6px",
                backgroundImage:
                  "url(https://tp-assets.sfo2.cdn.digitaloceanspaces.com/device-lab/iOS.png)",
                backgroundSize: "auto 103%",
                backgroundPosition: "center",
                textAlign: "center",
                marginRight: "10px",
                boxShadow: "0px 1px 2px 1px #dddddd",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  width: "100%",
                  zIndex: "-1",
                  height: "100%",
                  position: "absolute",
                  left: "0",
                  top: "0",
                  pointerEvents: "none",
                  backgroundColor: "black",
                  opacity: "0",
                  transition: "opacity 0.2s",
                }}
              />
              <p
                style={{
                  fontSize: "38px",
                  fontWeight: "600",
                  marginBottom: "12px",
                  textShadow: "1px 1px #b1b1b15c",
                  pointerEvents: "none",
                }}
              >
                {this.props.ios_device_count}
              </p>
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "17px",
                  textShadow: "1px 1px #b1b1b15c",
                  pointerEvents: "none",
                }}
              >
                iOS Devices
              </p>
            </div>
            <div
              className="client-device-lab-card"
              onClick={this.handleCardClick}
              data-card-key="android"
              style={{
                width: "calc(20% - 10px)",
                zIndex: "1",
                padding: "23px 0 20px",
                position: "relative",
                maxWidth: "220px",
                minWidth: "145px",
                height: "110px",
                color: "white",
                fontFamily: "manrope",
                borderRadius: "6px",
                backgroundImage:
                  "url(https://tp-assets.sfo2.cdn.digitaloceanspaces.com/device-lab/Android.png)",
                backgroundSize: "auto 103%",
                backgroundPosition: "center",
                textAlign: "center",
                marginRight: "10px",
                boxShadow: "0px 1px 2px 1px #dddddd",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  width: "100%",
                  zIndex: "-1",
                  height: "100%",
                  position: "absolute",
                  left: "0",
                  top: "0",
                  pointerEvents: "none",
                  backgroundColor: "black",
                  opacity: "0",
                  transition: "opacity 0.2s",
                }}
              />
              <p
                style={{
                  fontSize: "38px",
                  fontWeight: "600",
                  marginBottom: "12px",
                  textShadow: "1px 1px #b1b1b15c",
                  pointerEvents: "none",
                }}
              >
                {this.props.android_device_count}
              </p>
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "17px",
                  textShadow: "1px 1px #b1b1b15c",
                  pointerEvents: "none",
                }}
              >
                Android Devices
              </p>
            </div>
            <div
              className="client-device-lab-card"
              onClick={this.handleCardClick}
              data-card-key="desktop"
              style={{
                width: "calc(20% - 10px)",
                zIndex: "1",
                padding: "23px 0 20px",
                position: "relative",
                maxWidth: "220px",
                minWidth: "145px",
                height: "110px",
                color: "white",
                fontFamily: "manrope",
                borderRadius: "6px",
                backgroundImage:
                  "url(https://tp-assets.sfo2.cdn.digitaloceanspaces.com/device-lab/Desktop.png)",
                backgroundSize: "auto 103%",
                backgroundPosition: "center",
                textAlign: "center",
                marginRight: "10px",
                boxShadow: "0px 1px 2px 1px #dddddd",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  width: "100%",
                  zIndex: "-1",
                  height: "100%",
                  position: "absolute",
                  left: "0",
                  top: "0",
                  pointerEvents: "none",
                  backgroundColor: "black",
                  opacity: "0",
                  transition: "opacity 0.2s",
                }}
              />
              <p
                style={{
                  fontSize: "38px",
                  fontWeight: "600",
                  marginBottom: "12px",
                  textShadow: "1px 1px #b1b1b15c",
                  pointerEvents: "none",
                }}
              >
                {this.props.desktop_device_count}
              </p>
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "17px",
                  textShadow: "1px 1px #b1b1b15c",
                  pointerEvents: "none",
                }}
              >
                Desktop Devices
              </p>
            </div>
            <div
              className="client-device-lab-card"
              onClick={this.handleCardClick}
              data-card-key="arvr"
              style={{
                width: "calc(20% - 10px)",
                zIndex: "1",
                padding: "23px 0 20px",
                position: "relative",
                maxWidth: "220px",
                minWidth: "145px",
                height: "110px",
                color: "white",
                fontFamily: "manrope",
                borderRadius: "6px",
                backgroundImage:
                  "url(https://tp-assets.sfo2.cdn.digitaloceanspaces.com/device-lab/AR%20_%20VR.png)",
                backgroundSize: "auto 103%",
                backgroundPosition: "center",
                textAlign: "center",
                marginRight: "10px",
                boxShadow: "0px 1px 2px 1px #dddddd",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  width: "100%",
                  zIndex: "-1",
                  height: "100%",
                  position: "absolute",
                  left: "0",
                  top: "0",
                  pointerEvents: "none",
                  backgroundColor: "black",
                  opacity: "0",
                  transition: "opacity 0.2s",
                }}
              />
              <p
                style={{
                  fontSize: "38px",
                  fontWeight: "600",
                  marginBottom: "12px",
                  textShadow: "1px 1px #b1b1b15c",
                  pointerEvents: "none",
                }}
              >
                {this.props.arvr_device_count}
              </p>
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "17px",
                  textShadow: "1px 1px #b1b1b15c",
                  pointerEvents: "none",
                }}
              >
                AR/VR Devices
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              marginBottom: "30px",
              marginTop: "20px",
              alignItems: "center",
            }}
          >
            <div
              id="ticketSearch"
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                marginRight: "20px",
              }}
            >
              <i
                aria-hidden="true"
                style={{
                  position: "absolute",
                  pointerEvents: "none",
                  left: "6px",
                  color: "#ababab",
                }}
                className="material-icons prefix"
              >
                search
              </i>
              <input
                id="bug-search-input"
                ref={this.inputRef}
                className="browser-default"
                type="text"
                autoComplete="false"
                onChange={this.handleSearch}
                // value={this.state.search}
                placeholder=" "
                aria-label="Device search input field"
              />
              {this.state.search != "" && (
                <button
                  aria-label="clear input"
                  onClick={this.clearInput}
                  className="link-btn material-icons grey-text"
                >
                  close
                </button>
              )}
            </div>
            <p
              aria-label={`Displaying ${searchCount} of ${totalDevices} employees`}
            >
              <span aria-hidden="true">
                Displaying {searchCount} of {totalDevices}
              </span>
            </p>
            <BasicSelect
              name="device-platform"
              options={[
                { label: "All", value: "all" },
                { label: "iOS", value: "ios" },
                { label: "Android", value: "android" },
                { label: "Desktop", value: "desktop" },
                { label: "AR/VR", value: "arvr" },
              ]}
              onChange={this.handleFilterChange}
              selectedOption={this.state.selectedFilter}
              placeholder="Sort"
              id="employee-sort"
              margin="0 0 0 20px"
              width="122px"
              maxHeight="145px"
              display="inline-block"
              cascadeTransition
              selectedTextStyle={{
                color: "#242424",
                fontWeight: "500",
                display: "block",
                width: "100px",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              containerStyle={{ verticalAlign: "bottom" }}
              menuTransitionTime={0.2}
            />
            <button
              onClick={this.handleSort}
              style={{
                border: "solid 1px lightgray",
                boxShadow: "1px 1px 3px 0px #f0f0f0",
                borderRadius: "5px",
                background: "none",
                display: "flex",
                width: "190px",
                justifyContent: "space-between",
                alignItems: "center",
                height: "37px",
                padding: "0 14px",
                cursor: "pointer",
              }}
            >
              <img
                style={{ width: "12px" }}
                src="https://tp-assets.sfo2.cdn.digitaloceanspaces.com/icons/Icon_TP_Sort.svg"
              />
              <span>
                {this.state.descending
                  ? "From newest to oldest"
                  : "From oldest to newest"}
              </span>
            </button>
          </div>
          {searchCount > 0 ? (
            <div>
              {deviceGroups.map((group) => {
                let devices = group[1];
                const deviceCount = {};
                devices = devices.filter((d) => {
                  if (deviceCount[`${d.device_name} ${d.device_os}`]) {
                    deviceCount[`${d.device_name} ${d.device_os}`] =
                      deviceCount[`${d.device_name} ${d.device_os}`] + 1;
                    return false;
                  }
                  deviceCount[`${d.device_name} ${d.device_os}`] = 1;
                  return true;
                });
                return (
                  <div
                    style={{ display: group[1].length > 0 ? "block" : "none" }}
                    key={group[0]}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        marginBottom: "20px",
                        marginTop: "10px",
                      }}
                    >
                      <p
                        style={{
                          backgroundColor: "#519acc",
                          color: "white",
                          fontFamily: "manrope",
                          fontWeight: "600",
                          padding: "0px 15px",
                          borderRadius: "14px",
                          marginRight: "10px",
                          lineHeight: "25px",
                          height: "26px",
                        }}
                      >
                        {group[0]}
                      </p>
                      <div
                        style={{
                          borderBottom: "solid 1px lightgray",
                          flex: "1",
                          marginRight: "20px",
                        }}
                      />
                    </div>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {devices.map((d) => {
                        let color = "#519acc";
                        if (d.device_platform === "Android") {
                          color = "#54c038";
                        } else if (
                          d.device_type === "Desktop" ||
                          d.device_type === "Laptop"
                        ) {
                          color = "#8f64aa";
                        } else if (d.device_type === "Wearable") {
                          color = "#ff5741";
                        }
                        return (
                          <div
                            key={d.id}
                            style={{
                              borderRadius: "6px",
                              width: "216px",
                              height: "100px",
                              backgroundColor: "#efefef",
                              boxShadow: "0px 1px 2px 1px #dddddd",
                              borderBottom: `solid 15px ${color}`,
                              textAlign: "center",
                              marginRight: "10px",
                              marginBottom: "10px",
                              padding: "15px 10px",
                              fontFamily: "manrope",
                            }}
                          >
                            <p
                              style={{
                                fontWeight: "700",
                                fontSize: "15px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {d.device_name}
                              {deviceCount[`${d.device_name} ${d.device_os}`] >
                                1 && (
                                <span style={{ color: "#adadad" }}>
                                  (
                                  {
                                    deviceCount[
                                      `${d.device_name} ${d.device_os}`
                                    ]
                                  }
                                  )
                                </span>
                              )}
                            </p>
                            <p
                              style={{
                                fontWeight: "500",
                                fontSize: "15px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {deviceCount[d.device_name] > 1
                                ? "Multiple OS Versions"
                                : d.device_os}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <p
              style={{
                color: "#c3c3c3",
                textAlign: "center",
                marginTop: "30px",
                fontSize: "18px",
                fontFamily: "manrope",
                fontWeight: "600",
              }}
            >
              No devices found
            </p>
          )}
        </div>
      </div>
    );
  }
}

export default ClientDeviceLab;
