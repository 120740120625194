import React from "react";
import PropTypes from "prop-types";
import parse from "html-react-parser";

class Activity extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.makeActivity = this.makeActivity.bind(this);
  }

  makeActivity(action) {
    const activity = {};
    try {
      switch (action) {
        case 1:
          activity.actionText = `${
            this.props.activity.build_history != undefined ? " " : ""
          }New ${
            this.props.activity.message.includes("apk") ? "Android" : "iOS"
          } uploaded a build`;
          activity.time = this.props.activity.time_difference;
          activity.description = "";
          activity.actionLinkText =
            this.props.activity.build_history != undefined
              ? ` - ${this.props.activity.build_history.build_name}`
              : "";
          activity.color = "#519acc";
          activity.link =
            this.props.activity != undefined
              ? this.props.activity.build_history != undefined
                ? `/projects/${this.props.activity.project_id}/builds`
                : ""
              : "";
          activity.title =
            this.props.activity.build_history != undefined
              ? this.props.activity.build_history.build_name
              : "";
          activity.icon_url =
            "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Uploaded_a_Build.svg";
          activity.user = this.props.activity.event_user_name;
          break;
        case 2:
          activity.actionText = `${
            this.props.activity.application != undefined ? " " : ""
          } created a project`;
          activity.time = this.props.activity.time_difference;
          activity.description = "";
          activity.actionLinkText =
            this.props.activity.application != undefined
              ? ` - ${this.props.activity.application.project.name}`
              : "";
          activity.color = "#519acc";

          activity.link =
            this.props.activity != undefined
              ? this.props.activity.application != undefined
                ? `/projects/${this.props.activity.ticket.project.id}/bugs`
                : ""
              : "";
          activity.title =
            this.props.activity.application != undefined
              ? this.props.activity.application.project.name
              : "";
          activity.icon_url =
            "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Create_Bug.svg";
          activity.user = this.props.activity.event_user_name;
          break;
        case 5:
          activity.actionText = " - created a Bug ";
          activity.time = this.props.activity.time_difference;
          activity.description = this.props.activity.info
            ? this.props.activity.info
            : "";
          activity.actionLinkText =
            this.props.activity.ticket != undefined &&
            this.props.activity.ticket.project != null
              ? `- ${this.props.activity.ticket.project.identifier} - ${this.props.activity.ticket.app_key}`
              : "";
          activity.title =
            this.props.activity.ticket != undefined
              ? this.props.activity.ticket.title
              : "";
          activity.color = "#65cb9e";
          activity.link =
            this.props.activity.ticket != undefined &&
            this.props.activity.ticket.project != null
              ? `/projects/${
                  this.props.activity.ticket.project.id
                }/bugs/${this.ticketIdentifier(
                  this.props.activity.ticket.project.identifier,
                  this.props.activity.ticket.app_key
                )}`
              : "";
          activity.more = true;
          activity.icon_url =
            "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Create_Bug.svg";
          activity.user = this.props.activity.event_user_name;
          break;
        case 6:
          if (
            this.props.activity.ticket_changes &&
            this.props.activity.ticket_changes.length >= 1
          ) {
            const changes = this.props.activity.ticket_changes.map(
              (change, index) => {
                if (change.length == 3) {
                  return (
                    <li style={{ fontSize: "12px" }} key={index}>
                      <span style={{ color: "#519acc" }}>{change[0]} -</span>
                      <span>
                        {` ${change[1].length}` >= 100
                          ? change[1].substring(0, 100)
                          : change[1]}
                      </span>
                      <span style={{ fontStyle: "italic" }}>{change[2]}</span>
                    </li>
                  );
                }
                if (change.length == 4) {
                  return (
                    <li style={{ fontSize: "12px" }} key={index}>
                      <div style={{ fontStyle: "italic" }} id="desc-changes">
                        <span style={{ color: "#519acc" }}>{change[0]} - </span>
                        {parse(change[3])}
                      </div>
                    </li>
                  );
                }
                return (
                  <li style={{ fontSize: "12px" }} key={index}>
                    <span style={{ color: "#519acc" }}>{change[0]} -</span>
                    <span>
                      {` ${change[1].length}` >= 100
                        ? change[1].substring(0, 100)
                        : change[1]}
                    </span>
                  </li>
                );
              }
            );
            activity.actionText = " - updated a Bug ";
            activity.description = changes;
            activity.actionLinkText =
              this.props.activity.ticket != undefined &&
              this.props.activity.ticket.project
                ? this.ticketIdentifier(
                    this.props.activity.ticket.project.identifier,
                    this.props.activity.ticket.app_key
                  )
                : "";
            activity.title =
              this.props.activity.ticket != undefined
                ? this.props.activity.ticket.title
                : "";
            activity.color = "#df5166";
            activity.link =
              this.props.activity.ticket != undefined &&
              this.props.activity.ticket != null &&
              this.props.activity.ticket.project != null
                ? `/projects/${
                    this.props.activity.ticket.project.id
                  }/bugs/${this.ticketIdentifier(
                    this.props.activity.ticket.project.identifier,
                    this.props.activity.ticket.app_key
                  )}`
                : "";
            activity.more = true;
            activity.icon_url =
              "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Create_Bug.svg";
            activity.time = this.props.activity.time_difference;
            activity.user = this.props.activity.event_user_name;
          } else {
            activity.actionText = " - updated a Bug ";
            activity.description = "";
            activity.actionLinkText =
              this.props.activity.ticket != undefined &&
              this.props.activity.ticket.project != null
                ? ` - ${this.ticketIdentifier(
                    this.props.activity.ticket.project.identifier,
                    this.props.activity.ticket.app_key
                  )}`
                : "";
            activity.title =
              this.props.activity.ticket != undefined
                ? this.props.activity.ticket.title
                : "";
            activity.color = "#df5166";
            activity.link =
              this.props.activity.ticket != undefined &&
              this.props.activity.application &&
              this.props.activity.ticket.project != null
                ? `/projects/${
                    this.props.activity.ticket.project.id
                  }/bugs/${this.ticketIdentifier(
                    this.props.activity.ticket.project.identifier,
                    this.props.activity.ticket.app_key
                  )}`
                : "";
            activity.more = true;
            activity.icon_url =
              "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Create_Bug.svg";
            activity.time = this.props.activity.time_difference;
            activity.user = this.props.activity.event_user_name;
          }
          break;
        case 7:
          activity.actionText = ` has added a new user to the project - ${this.props.activity.event_user_name}`;
          activity.description = "";
          activity.title = "A new user has been added to the project";
          activity.icon_url =
            "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Added_User.svg";
          activity.time = this.props.activity.time_difference;
          activity.user = this.props.activity.by_user_name;
          break;
        case 8:
          activity.actionText = " created a Test Pass:";
          activity.description = "";
          activity.link = `/projects/${this.props.activity.project_id}/test_passes/${this.props.activity.test_pass_id}`;
          activity.actionLinkText = ` - ${this.props.activity.test_pass_name}`;
          activity.icon_url =
            "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Created_Test_Pass.svg";
          activity.time = this.props.activity.time_difference;
          activity.user = this.props.activity.event_user_name;
          break;
        case 9:
          activity.actionText = " A Test Pass has been completed:";
          activity.description = "";
          activity.link = `/projects/${this.props.activity.project_id}/test_passes/${this.props.activity.test_pass_id}`;
          activity.actionLinkText = ` - ${this.props.activity.test_pass_name}`;
          activity.icon_url =
            "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Created_Test_Pass.svg";
          activity.time = this.props.activity.time_difference;
          activity.user = this.props.activity.event_user_name;
          break;
        case 10:
          activity.actionText = " activated a Test Pass:";
          activity.description = "";
          activity.link = `/projects/${this.props.activity.project_id}/test_passes/${this.props.activity.test_pass_id}`;
          activity.actionLinkText = ` - ${this.props.activity.test_pass_name}`;
          activity.icon_url =
            "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Retired_Test_Pass.svg";
          activity.time = this.props.activity.time_difference;
          activity.user = this.props.activity.event_user_name;
          break;
        case 11:
          activity.actionText = " retired a Test Pass:";
          activity.description = "";
          activity.link = `/projects/${this.props.activity.project_id}/test_passes/${this.props.activity.test_pass_id}`;
          activity.actionLinkText = ` - ${this.props.activity.test_pass_name}`;
          activity.icon_url =
            "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Retired_Test_Pass.svg";
          activity.time = this.props.activity.time_difference;
          activity.user = this.props.activity.event_user_name;
          break;
        default:
          activity.actionText = "";
          activity.description = "";
          activity.actionLinkText = "";
          activity.link = "";
          activity.icon_url =
            "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Create_Bug.svg";
          activity.time = this.props.activity.time_difference;
          activity.user = this.props.activity.event_user_name;
      }
    } catch (err) {
      err.message = "Activity Feed Error Frontend";
      console.error(err);
      Sentry.captureException(err);
    }
    return activity;
  }

  ticketIdentifier(input, id) {
    return `${input}-${id}`;
  }

  render() {
    const activity = this.makeActivity(this.props.activity.action);

    return (
      <div
        style={{
          boxShadow: this.props.length >= 2 ? "" : "none",
          cursor: activity.link ? "pointer" : "auto",
        }}
        className="activity"
        onClick={() => {
          if (activity.link) {
            window.location.href = activity.link;
          }
        }}
      >
        <div className="activityInner">
          <div
            style={{
              alignItems: this.props.activity.ticket_changes
                ? "flex-start"
                : "",
            }}
            className="activityTitle"
          >
            <div style={{ width: "70px", height: "70px", display: "flex" }}>
              <img
                style={{ marginLeft: "-15px" }}
                alt="activity icon"
                src={activity.icon_url}
              />
            </div>
            <div
              className="base-flex-row"
              style={{
                padding: "0px 10px 0 15px",
                flex: 1,
                alignItems: this.props.activity.ticket_changes
                  ? "flex-start"
                  : "center",
                justifyContent: "space-between",
              }}
            >
              <div
                id="activityContent"
                style={{
                  fontSize: "14px",
                  fontWeight: 600,
                  paddingRight: "0px",
                }}
              >
                <span>{activity.user}</span>
                <span>{activity.actionText}</span>
                {activity.link && (
                  <a href={activity.link} className="activity-link">
                    {activity.actionLinkText}
                  </a>
                )}
                {this.props.activity.ticket_changes && (
                  <ul style={{ flex: "1 0 100%" }}>{activity.description}</ul>
                )}
              </div>
              <p style={{ minWidth: "120px", textAlign: "right" }}>
                {activity.time}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Activity;

Activity.propTypes = {
  project: PropTypes.object,
  big: PropTypes.bool,
  activity: PropTypes.object,
  closeUser: PropTypes.func.isRequired,
  activeUserId: PropTypes.number,
  currentUser: PropTypes.object,
  activeCardHandler: PropTypes.func.isRequired,
};
