import React, { useState, useReducer, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import Select from "react-select";
import SingleSelectStyles from "../common/select_styles";
import { dropdownIndicator, customControl } from "../common/select_utils";
import EodReportRow from "./eod_report_row";
import NoEODReportIcon from "../icons/dashboard/no_eod_report_icon";

function searchReducer(state, action) {
  switch (action.type) {
    case "search":
      var update = action.reports.filter((x) => x.tester_id == action.action);
      return update;
    case "reset":
      var update = action.reports;
      return update;
  }
}

function reportReducer(state, action) {
  switch (action.type) {
    case "add":
      return [action.id, ...state];
    case "reset":
      var update = action.id;
      return update;
    case "remove":
      var selectedIndex = state.findIndex((item) => item.id === action.id);
      if (selectedIndex < 0) {
        return state;
      }
      var update = [...state];
      update.splice(selectedIndex, 1);
      return update;
    case "update":
      var selectedIndex = state.findIndex((item) => item.id === action.id);
      if (selectedIndex < 0) {
        return state;
      }
      var update = [...state];
      update.splice(selectedIndex, 1, action);
      return update;
    default:
      return state;
  }
}

function EodReportsContainer(props) {
  const [loading, setLoading] = useState(false);
  const [userOptions, setUserOptions] = useState([]);
  const [managerOptions, setManagerOptions] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [permReports, setPermReports] = useState([]);
  const [reports, setReports] = useReducer(reportReducer, []);
  const [searchReports, setSearchReports] = useReducer(searchReducer, []);
  const [mounted, setMounted] = useState(false);

  function handleSelectedUser(e) {
    setSelectedUser(e);
  }

  function getData() {
    setLoading(true);
    var url = `/projects/${props.projectId}/report_rows.json`;
    axios
      .get(url)
      .then((res) => {
        setReports({ id: res.data.reports, type: "reset" });
        setPermReports(res.data.reports);
        setUserOptions(res.data.userOptions);
        setManagerOptions(res.data.managerOptions);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (mounted === false) {
      setMounted(true);
    }
    getData();
    return () => {};
  }, [props.projectId]);

  useEffect(() => {
    if (selectedUser && selectedUser.value) {
      setSearchReports({
        reports: permReports,
        action: selectedUser.value,
        type: "search",
      });
    } else {
      setSearchReports({ reports: permReports, type: "reset" });
    }
  }, [selectedUser]);

  function buttonHandler(id) {
    if (id) {
      props.handleViewEod(id, props.projectId, managerOptions);
    }
  }

  return (
    <ReportsList>
      {props.eodReportCount > 0 ? (
        <div style={{ height: "100%" }}>
          <div className="top-row">
            <p className="performance-large-title">EOD Report Submissions</p>
            <div>
              <Select
                placeholder="Filter By User"
                aria-label="User dropdown for eod report submissions"
                value={selectedUser}
                styles={SingleSelectStyles}
                onChange={handleSelectedUser}
                isClearable={true}
                name="user"
                blurInputOnSelect={true}
                closeMenuOnSelect={true}
                isDisabled={
                  loading ||
                  (mounted &&
                    selectedUser == null &&
                    searchReports.length == 0 &&
                    reports.length == 0)
                }
                options={userOptions}
                components={{
                  DropdownIndicator: dropdownIndicator,
                  Control: customControl,
                }}
                classNamePrefix="selectbox-menu"
              />
            </div>
          </div>
          <div
            className="new-scrollbar"
            style={{
              maxHeight: "calc(100% - 80px)",
              height: "100%",
              overflow: "hidden auto",
            }}
          >
            {loading ? (
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div id="test-step-bugs-loader" />
              </div>
            ) : (
              <ReportsTable style={{ width: "100%" }}>
                <>
                  {selectedUser == null && reports.length == 0 ? (
                    <tbody>
                      <tr>
                        <td
                          style={{ color: "black", textAlign: "center" }}
                          colSpan={5}
                        >
                          No EOD submissions for selected project
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <thead>
                      <tr
                        style={{ height: "30px", backgroundColor: "#519acc" }}
                      >
                        <th>Tester</th>
                        <th>Submitted</th>
                        <th>Bugs Filed</th>
                        <th>Hours Worked</th>
                        <th>Test Steps Complete</th>
                      </tr>
                    </thead>
                  )}
                </>
                <tbody>
                  {selectedUser && selectedUser.value
                    ? searchReports.map((report) => {
                        return (
                          <EodReportRow
                            key={report.id}
                            report={report}
                            handler={buttonHandler}
                          />
                        );
                      })
                    : reports.map((report) => {
                        return (
                          <EodReportRow
                            key={report.id}
                            report={report}
                            handler={buttonHandler}
                          />
                        );
                      })}
                  {selectedUser &&
                    selectedUser.value &&
                    searchReports.length == 0 && (
                      <tr>
                        <td
                          style={{ color: "black", textAlign: "center" }}
                          colSpan={5}
                        >
                          No reports for selected user
                        </td>
                      </tr>
                    )}
                </tbody>
              </ReportsTable>
            )}
          </div>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "5px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <NoEODReportIcon width={60} height={60} />
          <h2 style={{ margin: "18px 0px" }}>No EOD Reports Found</h2>
          <p
            style={{
              maxWidth: "420px",
              textAlign: "center",
              lineHeight: "22px",
              marginBottom: "20px",
            }}
          >
            View productivity results by submitting at least one end of day
            report using the button below
          </p>
          <button
            style={{ width: "150px", height: "48px" }}
            onClick={props.handleEodModal}
            className="common-button-submit"
          >
            <img
              aria-hidden={true}
              style={{
                width: "15px",
                height: "15px",
                marginRight: "5px",
                verticalAlign: "text-top",
              }}
              src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Generate_Report.svg"
            />
            EOD Report
          </button>
        </div>
      )}
    </ReportsList>
  );
}

const ReportsList = styled.div`
  display: flex;
  border-radius: 5px;
  height: 100%;
  box-shadow: 0px 0px 3px 1px lightgrey;
  padding: 10px 30px;
  margin: 5px;
  &:first-child {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .top-row {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const ReportsTable = styled.table`
  thead {
    background-color: #519acc;
    color: white;
    position: sticky;
    top: 0;
  }
  td,
  th {
    white-space: pre;
    padding: 0 10px !important;
  }
  td,
  th {
    border-radius: 0px;
    transition: color 0.5s;
  }
  tbody > tr > td:nth-child(1) {
    text-align: left;
  }
  thead > tr > th:nth-child(1) {
    text-align: left;
  }
  thead > tr > th {
    text-align: center;
  }
  tbody > tr > td {
    color: #242424;
    text-align: center;
  }
  tbody {
    max-height: 300px;
    overflow: hidden auto;
  }
  tr {
    height: 50px;
    white-space: pre;
    padding: 0 10px !important;
    text-align: center;
    transition: color 0.5s, background-color 0.5s;
    &:hover {
      cursor: pointer;
      background-color: #c8dbec;
      > td:nth-child(1) {
        color: #242424;
      }
    }
  }
  tr:nth-child(even) {
    background-color: lightgray;
    &:hover {
      cursor: pointer;
      background-color: #c8dbec;
      > td:nth-child(1) {
        color: #242424;
      }
    }
  }
`;

export default EodReportsContainer;
