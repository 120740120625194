import React, { useContext, useMemo, useEffect, useRef } from "react";
import styled from "styled-components";
import { useFormContext, useWatch } from "react-hook-form";
import { IconContainer, Content } from "./TrialBody";
import Icons from "../../icons";
import { Headline, QuestionDescription } from "./SingleAnswer";
import { TrialsContext } from "./index";
import { questionTypes } from "../../../types/wiki/trial";

const WrittenAnswer = (props) => {
  const { question } = props;
  const { assessment } = useContext(TrialsContext);

  const innerRef = useRef();

  const { register, setValue, reset } = useFormContext();

  const ID = useMemo(() => `${question.id}`, [question.id]);
  const CURRENT = useWatch({ name: ID, defaultValue: "" });

  const handleChange = (answer) => {
    if (answer && answer.length <= 140) setValue(ID, answer);
  };

  useEffect(() => {
    reset();
    register(ID, {
      value: "",
      shouldUnregister: true,
      required: {
        message: "Please enter a response before submission.",
        value: true,
      },
      maxLength: {
        value: 140,
        message: "Please enter a response less than 140 characters.",
      },
    });

    if (question.previous_response) {
      setValue(ID, question.previous_response.written_response);
    }

    if (innerRef.current) innerRef.current.focus();
  }, [question]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Content>
      <Headline>
        <IconContainer>
          <Icons.BookIcon height="21px" width="29px" color="#ffffff" />
        </IconContainer>
        <h3>
          Test Your Knowledge on {assessment.resource.title} (Level{" "}
          {assessment.level})
        </h3>
      </Headline>
      <QuestionDescription>
        <h4>Question {question.question_number} - Written Answer</h4>
        <p>{question.description}</p>
      </QuestionDescription>
      <InputContainer>
        <label>Answer</label>
        <Input
          ref={(node) => {
            innerRef.current = node;
          }}
          type="text"
          className="browser-default"
          value={CURRENT || ""}
          onChange={(e) => handleChange(e.target.value)}
        />
        {typeof CURRENT === "string" && (
          <CharacterLimit>{CURRENT.length} / 140</CharacterLimit>
        )}
      </InputContainer>
    </Content>
  );
};

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    font-size: 16px;
    font-family: "Arial";
    font-weight: 400;
    color: #000000;
  }
`;

const Input = styled.input`
  width: 100%;
  height: 43px;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  padding: 12px 16px;
  font-size: 13px;
  line-height: 20px;
  font-family: "Arial";
`;

const CharacterLimit = styled.div`
  margin-top: 8px;
  font-size: 12px;
  line-height: 24px;
`;

WrittenAnswer.propTypes = {
  question: questionTypes.isRequired,
};

export default WrittenAnswer;
