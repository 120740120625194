import PropTypes from "prop-types";

const { shape, number, string, bool } = PropTypes;

const deviceTypes = shape({
  active: bool,
  checkout_active: bool,
  device_make: string,
  device_make_id: number,
  device_name: string,
  device_name_id: number,
  device_os: string,
  device_os_id: number,
  device_platform: string,
  device_platform_id: number,
  device_size: string,
  device_size_id: number,
  device_type: string,
  device_type_id: number,
  id: number,
  imei: string,
  main_building: bool,
  notes: string,
  office: bool,
  office_only: bool,
  release_date: string,
  serial: string,
  sim: string,
  tier: number,
  udid: string,
  user: shape({
    id: number,
    name: string,
    avatar: string,
    devices_checked_out: number,
  }),
  visible: bool,
  visible_notes: string,
});

export default deviceTypes;
