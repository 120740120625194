import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-use";
import queryString from "query-string";
import _ from "lodash";
import testPlatformApi, {
  useGetDeviceLabIndexQuery,
  useGetDeviceLabSettingsQuery,
  useGetUserDeviceTierQuery,
  useGetUserDevicesQuery,
  useGetDeviceCheckoutDateQuery,
  useCheckOutDeviceMutation,
  useCheckInDeviceMutation,
  useEditDeviceMutation,
  useCreateDeviceMutation,
  useUpdateDeviceLabSettingsMutation,
} from "@rdx/slices/api";
import {
  DEVICE_LAB_INDEX_TAG,
  DEVICE_LAB_USER_DEVICES_TAG,
} from "@rdx/slices/deviceLab";

const QUERY_PARAMS = [
  "available",
  "home",
  "office_only",
  "office",
  "page",
  "platforms",
  "search",
  "sim",
  "teams",
  "tier1",
  "tier2",
  "sort",
  "order",
];
const [
  AVAILABLE,
  HOME,
  OFFICE_ONLY,
  OFFICE,
  PAGE,
  PLATFORMS,
  SEARCH,
  SIM,
  TEAMS,
  TIER1,
  TIER2,
  SORT,
  ORDER,
] = QUERY_PARAMS;

const SORT_KEYS = [
  "id",
  "device_type",
  "device_platform",
  "device_name",
  "device_os",
  "serial",
  "user",
  "device_make",
  "release_date",
  "device_count",
  SIM,
];

const [
  ID,
  TYPE,
  PLATFORM,
  NAME,
  OS,
  SERIAL,
  USER,
  MAKE,
  RELEASE_DATE,
  DEVICE_COUNT,
] = SORT_KEYS;

const SORT_ORDER = ["desc", "asc"];

const [DESC, ASC] = SORT_ORDER;

export default () => {
  const location = useLocation();
  const dispatch = useDispatch();

  // parse current search string to hash
  // sanitize params via _.pick and only select valid params
  const searchParams = useMemo(() => {
    const params = _.pick(
      queryString.parse(location.search, { arrayFormat: "bracket" }),
      QUERY_PARAMS
    );

    if (!params[SORT] || !SORT_KEYS.includes(params[SORT])) {
      params[SORT] = ID;
      params[ORDER] = DESC;
    }

    if (params[ORDER] && !SORT_ORDER.includes(params[ORDER])) {
      delete params.order;
    }

    return params;
  }, [location.search]);

  // data fetching and mutations
  const listQuery = useGetDeviceLabIndexQuery(
    queryString.stringify(searchParams, {
      arrayFormat: "bracket",
    })
  );
  const settingsQuery = useGetDeviceLabSettingsQuery();

  // statuses
  const isLoading = useMemo(() => {
    return listQuery.isFetching || settingsQuery.isFetching;
  }, [listQuery.isFetching, settingsQuery.isFetching]);

  const isErrored = useMemo(() => {
    return listQuery.isError || settingsQuery.isError;
  }, [listQuery.isError, settingsQuery.isError]);

  // current filter values, based on search string.
  const currentPlatforms = useMemo(() => {
    return (
      searchParams[PLATFORMS]?.map((platformId) => Number(platformId)) || []
    );
  }, [searchParams]);

  const currentTeams = useMemo(() => {
    return searchParams[TEAMS]?.map((teamId) => Number(teamId)) || [];
  }, [searchParams]);

  const currentStatuses = useMemo(() => {
    return _.pick(searchParams, [
      AVAILABLE,
      OFFICE,
      HOME,
      OFFICE_ONLY,
      SIM,
      TIER1,
      TIER2,
    ]);
  }, [searchParams]);

  const currentSearch = useMemo(() => {
    return searchParams[SEARCH] || "";
  }, [searchParams]);

  const currentSort = useMemo(() => {
    return searchParams[SORT];
  }, [searchParams]);

  const currentSortOrder = useMemo(() => {
    return searchParams[ORDER];
  }, [searchParams]);

  // invalidate cache and refetch list and user devices queries
  // currently being used in response to any DeviceLabChannel action cable events
  const refetch = () => {
    const result = testPlatformApi.util.invalidateTags([
      DEVICE_LAB_INDEX_TAG,
      DEVICE_LAB_USER_DEVICES_TAG,
    ]);
    dispatch(result);
  };

  return {
    list: listQuery?.data?.devices || [],
    paging: listQuery?.data?.paging || {},
    checkoutStats: listQuery?.data?.checkout_statistics || {},
    settings: settingsQuery?.data || {},
    isLoading,
    isErrored,
    refetch,
    filters: {
      query: searchParams,
      platforms: currentPlatforms,
      teams: currentTeams,
      statuses: currentStatuses,
      search: currentSearch,
      sort: currentSort,
      sortOrder: currentSortOrder,
    },
    queries: {
      listQuery,
      settingsQuery,
    },
    hooks: {
      queries: {
        useGetDeviceLabIndex: useGetDeviceLabIndexQuery,
        useGetDeviceLabSettings: useGetDeviceLabSettingsQuery,
        useGetUserDevices: useGetUserDevicesQuery,
        useGetUserDeviceTier: useGetUserDeviceTierQuery,
        useGetDeviceCheckoutDate: useGetDeviceCheckoutDateQuery,
      },
      mutations: {
        useCheckOutDevice: useCheckOutDeviceMutation,
        useCheckInDevice: useCheckInDeviceMutation,
        useCreateDevice: useCreateDeviceMutation,
        useEditDevice: useEditDeviceMutation,
        useUpdateSettings: useUpdateDeviceLabSettingsMutation,
      },
    },
    PARAM_KEYS: {
      QUERY_PARAMS,
      AVAILABLE,
      HOME,
      OFFICE_ONLY,
      OFFICE,
      PAGE,
      PLATFORMS,
      SEARCH,
      SIM,
      TEAMS,
      TIER1,
      TIER2,
    },
    SORT_KEYS: {
      ID,
      TYPE,
      PLATFORM,
      NAME,
      OS,
      SERIAL,
      USER,
      MAKE,
      RELEASE_DATE,
      DEVICE_COUNT,
      SIM,
    },
    SORT_ORDER: {
      DESC,
      ASC,
    },
  };
};
