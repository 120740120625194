import React from 'react'
import ReactDOM from 'react-dom'
import Swal from "sweetalert2";
import axios from 'axios'
import ReactGA from 'react-ga'

ReactGA.initialize(`${process.env.G_ANALYTICS_TAG}`, {
  debug: !process.env.NODE_ENV == "production",
  titleCase: false
}); 

class TestPassResultRow extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			testPassResults: this.props.testPassResults
		}
		this.handleResultChange = this.handleResultChange.bind(this)
		this.setOutline = this.setOutline.bind(this)
		this.handleCableUpdate = this.handleCableUpdate.bind(this)
		this.resultKeyDown = this.resultKeyDown.bind(this)
		this.setCurrentColumn = this.setCurrentColumn.bind(this)
		this.repeatHelper = "."
		this.testCaseTextListener = (e) => { 
			if(!this.elem1.contains(document.activeElement)){
				this.elem1.setAttribute("aria-hidden", "true")
				window.removeEventListener("keyup", this.testCaseTextListener)
				window.removeEventListener("click", this.testCaseTextListener)
				this.elem1 = null
			} else if(e.keyCode === 82){
				this.elem1.setAttribute("aria-hidden", "true")
				window.removeEventListener("keyup", this.testCaseTextListener)
				window.removeEventListener("click", this.testCaseTextListener)
				this.elem1 = null
				var elem = document.querySelector(`.test-case-result-${this.props.index1}-${this.props.index2}-${this.props.currentColumn}`)
      	if(elem){
        	elem.focus()
      	}
			}
		}
	}

	getSnapshotBeforeUpdate(prevProps, prevState) {
		if (prevProps.testPassResults != this.props.testPassResults) {
		  return this.props.testPassResults
		}
		return null;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (snapshot !== null) {
		  var testPassResults = snapshot
		  this.setState({
			  testPassResults
		  })
		}
	}

	componentWillUnmount(){
		if(this.elem1){
			window.removeEventListener("keyup", this.testCaseTextListener)
			window.removeEventListener("click", this.testCaseTextListener)
		}	
	}

	handleCableUpdate(testResults){
		this.setState({testPassResults: testResults})
	}

	handleResultChange(e) {
		var v = e.target.value
    var x = document.getElementById(e.target.id)
		var colors = {"0": "white", "1": "#9DDEA8", "2": "#DC918D", "3": "#F5EE89", "4": "#519acc", "5": "lavender" }
    x.style.backgroundColor = colors[v]
    x.style.color = v === "0" ? "transparent" : "black"
		var elementIds = e.target.id.split("-")
		var newResults = this.state.testPassResults
		var elem = document.getElementById("aria-custom-status")
		newResults[parseInt(elementIds[5])] = parseInt(v)
		this.setState({
			testPassResults: newResults
		})
    axios.patch('/update_test_result', {
      headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
      },
      id: parseInt(elementIds[4]),
      index: parseInt(elementIds[5]),
      value: parseInt(v)
    }).then((res) => {
      switch(parseInt(v)) {
				case 0:
					ReactGA.event({ category: 'Test Pass Result', action: 'Untested Result' });
					elem.innerHTML="Result set to Untested"
					break;
				case 1: 
					ReactGA.event({ category: 'Test Pass Result', action: 'Pass Result' });
					elem.innerHTML="Result set to Pass"
					break;
				case 2: 
					ReactGA.event({ category: 'Test Pass Result', action: 'Fail Result' });
					elem.innerHTML="Result set to Fail"
					break;
				case 3: 
					ReactGA.event({ category: 'Test Pass Result', action: 'Block Result' });
					elem.innerHTML="Result set to Blocked"
					break;
				case 4: 
					ReactGA.event({ category: 'Test Pass Result', action: 'Retest Result' });
					elem.innerHTML="Result set to Retest"
					break;
				case 5: 
					ReactGA.event({ category: 'Test Pass Result', action: 'N/A Result' });
					elem.innerHTML="Result set to N/A" 
					break;
      }
      if (this.props.testPassPercent != "100%" && res.data["complete?"]) {
        ReactGA.event({ category: 'Test Pass', action: 'Test Pass Completed' });
      }
    })
    .catch((err) => {
		console.log(err)
	  Swal.fire({title: "TC result error", text:"There was a problem updating this test case result", customClass: "swal2-error-modal"});
    })
	}
	
	setOutline(e){
		this.props.setResultOutline(
			e.target.offsetParent.offsetLeft, 
			e.target.offsetParent.offsetTop, 
			e.target.offsetParent.clientHeight,
			parseInt(e.target.dataset.row),
			parseInt(e.target.dataset.column)
		)
	}

	onResultDragOver(e){
		e.preventDefault()
	}

	resultKeyDown(e){
		var elem = document.getElementById("aria-custom-status")
		if(this.repeatHelper === "."){
			this.repeatHelper = ""
		} else {
			this.repeatHelper = "."
		}
		if(elem){
			switch(e.keyCode){
				case 72:
					this.props.keyboardShortcuts(this.props.index1, this.props.index2)
					// elem.innerHTML= `S: Test case text. T: View and add bugs. T+shift: View and add device specific bugs. C: View and add comments. Shift+C: View and add device specific comments. A: View and add WCAG violations. D: Device column info. Z: Jump to top of device column. Test result options correspond to first letter, example: P for pass${this.repeatHelper}`
					break;
				case 83: //read test case text
					this.elem1 = document.getElementById(`test-step-${this.props.index1}-${this.props.index2}`)
					var elem2 = document.querySelector(`#test-step-${this.props.index1}-${this.props.index2} > h4:nth-child(1)`)
					if(this.elem1 && elem2){
						this.elem1.setAttribute("aria-hidden", "false")
						// window.addEventListener("keyup", this.testCaseTextListener)
						// window.addEventListener("click", this.testCaseTextListener)
							setTimeout(()=>{
								elem2.focus()
								window.addEventListener("keyup", this.testCaseTextListener)
								window.addEventListener("click", this.testCaseTextListener)
							}, 80)
					} else {
						elem.innerHTML= `${this.props.step.text}${this.repeatHelper}`
					}
					break;
				// case 69: //read expected results
				// 	elem.innerHTML= `${this.props.step.details?this.props.step.details:"No expected results present"}${this.repeatHelper}`
				// 	break;
				case 68: //repeat current column info 
					var device = JSON.parse(e.target.dataset.device)
					var percent = document.getElementById(`device_${device.id}_text`).innerText
					elem.innerHTML= `${device.device_name?device.device_name + ", ":""} ${device.os_name}${device.browser_name?", "+device.browser_name:""}. ${device.assignee_id > 0? "Assigned to " + this.props.projectUsersList[device.assignee_id] : "Unassigned"}. ${percent} percent complete${this.repeatHelper}`
					break;
				case 90: //jump to top of column
					document.querySelector(`h3.device-index-${this.props.currentColumn}`).focus()
					break;
				case 67: //Comments
					if(e.shiftKey){
						var elem = document.getElementById(`result-comment-button-${this.props.index1}-${this.props.index3}-${e.target.dataset.index}`)
						if(elem){elem.click()}
					} else {
						var elem = document.getElementById(`test-step-comments-button-${this.props.stepId}`)
						if(elem){elem.click()}
					}
					break;
				case 84: //Bug Tickets
					if(e.shiftKey){
						var elem = document.getElementById(`result-bug-button-${this.props.index1}-${this.props.index3}-${e.target.dataset.index}`)
						if(elem){elem.click()}
					} else {
						var elem = document.getElementById(`test-step-bugs-button-${this.props.stepId}`)
						if(elem){elem.click()}
					}
					break;
				case 65: //a11y violations
					var elem = document.getElementById(`test-step-violations-button-${this.props.stepId}`)
					if(elem){elem.click()}
					break;
				case 40: //down arrow
					e.preventDefault()
					var resultElem = document.querySelector(`.test-case-result-${this.props.index1}-${this.props.index2+1}-${e.target.dataset.index}`)
					if(resultElem){
						resultElem.focus()
					} else {
						var sectionHeadingElem = document.querySelector(`#test-case-${this.props.index1+1} > button`)
						if(sectionHeadingElem){
							sectionHeadingElem.focus()
						} else {
							elem.innerHTML=`End of test cases${this.repeatHelper}`
						}
					}
					break;
				case 38: //up arrow
					e.preventDefault()
					var resultElem = document.querySelector(`.test-case-result-${this.props.index1}-${this.props.index2-1}-${e.target.dataset.index}`)
					if(resultElem){
						resultElem.focus()
					} else {
						var sectionHeadingElem = document.querySelector(`#test-case-${this.props.index1} > button`)
						if(sectionHeadingElem){
							sectionHeadingElem.focus()
						} 
					}
					break;
				case 37: //left arrow
					e.preventDefault()
					var resultElemContainer = document.getElementById(`test-case-result-container-${this.props.index1}-${this.props.index3}-${parseInt(e.target.dataset.index)-1}`)
					if(resultElemContainer && resultElemContainer.style.display === "inline-flex"){
						document.querySelector(`.test-case-result-${this.props.index1}-${this.props.index2}-${parseInt(e.target.dataset.index)-1}`).focus()
					} else {
						elem.innerHTML= `No more columns in that direction${this.repeatHelper}`
					}
					break;
				case 39: //right arrow
					e.preventDefault()
					var resultElemContainer = document.getElementById(`test-case-result-container-${this.props.index1}-${this.props.index3}-${parseInt(e.target.dataset.index)+1}`)
					if(resultElemContainer && resultElemContainer.style.display === "inline-flex"){
						document.querySelector(`.test-case-result-${this.props.index1}-${this.props.index2}-${parseInt(e.target.dataset.index)+1}`).focus()
					} else {
						elem.innerHTML= `No more columns in that direction${this.repeatHelper}`
					}
					break;
			}
		}
	}

	setCurrentColumn(e){
    var currentColumn = parseInt(e.target.dataset.index)
		this.props.setCurrentColumn(currentColumn)
  }

	render() {
		var row = this.props.row
		var index1 = this.props.index1
		var index2 = this.props.index2
		var index3 = this.props.index3
    return  this.state.testPassResults.map((result, index) => {
						var colors = {1: "rgba(157, 222, 168, 0.5)", 2: "rgba(220, 145, 141, 0.5)", 3: "rgba(245, 238, 137, 0.5)", 4: "rgba(81, 155, 204, 0.5)", 5: "lavender", 0: "white"}
						var color = colors[result]
						var display = this.props.testDevices[index] != undefined ? `${this.props.testDevices[index].device_platform_id === this.props.currentPlatform?"inline-flex":"none"}`: null
						var device = this.props.testDevices[index]
						var deviceTicketCounts = device && this.props.ticketCounts[`${device.device_name} ${device.os_name} ${device.browser_name}`] ? this.props.ticketCounts[`${device.device_name} ${device.os_name} ${device.browser_name}`] : ""
						var deviceCommentCounts = device && this.props.commentCounts[`${device.device_name} ${device.os_name} ${device.browser_name}`] ? this.props.commentCounts[`${device.device_name} ${device.os_name} ${device.browser_name}`] :""
						return (
							<div 
								className="result-container"
								id={`test-case-result-container-${index1}-${index3}-${index}`}  
								key={`test-case-result-${index1}-${index3}-${index}`} 
								style={{ position:"relative", height:"100%", alignItems:"center", display: display, borderBottom: index < this.state.testPassResults.length ? "solid 1px gray" : "none" }}
							>
								<div 
									id={`result-drag-area-${index1}-${index3}-${index}`}
									style={{ position:"absolute", left:"0px", top:"0px", width:"100%", height:"100%", zIndex: this.props.draggingResult ? "2" : "-1" }}
									data-column={index}
									data-row={row}
									onDragOver={this.onResultDragOver}
									onDragEnter={this.props.onResultDragEnter}
								/>
								<i 
									aria-hidden="true"
									className="material-icons result-other-button" 
									id={`result-bug-button-${index1}-${index3}-${index}`}
									data-step-id={this.props.stepId}
									data-test-case-number={this.props.testCaseNumber}
									onClick={this.props.onBugClick}
									index1={index1}
									index2={index2}
									onMouseEnter={this.props.onBugMouseEnter}
									onMouseLeave={this.props.onBugMouseLeave}
									data-device={device?`${device.device_name} ${device.os_name} ${device.browser_name}`:""}
									data-bugs={device?this.props.ticketCounts[`${device.device_name} ${device.os_name} ${device.browser_name}`]:[]}
									style={{right:"70px", display: device && this.props.ticketCounts[`${device.device_name} ${device.os_name} ${device.browser_name}`]? "block" : "none"}}>bug_report</i>
									{deviceTicketCounts ? <span aria-hidden="true" className="counter test-step-result-bugs-button-counter">{deviceTicketCounts}</span> : ""}
								<i 
									aria-hidden="true"
									className="material-icons result-other-button"
									data-step-id={this.props.stepId}
									data-count={deviceCommentCounts?deviceCommentCounts:0}
									data-comments={device?this.props.commentCounts[`${device.device_name} ${device.os_name} ${device.browser_name}`]:[]}
									data-test-case-number={this.props.testCaseNumber}
									data-current-user-id={this.props.currentUserId}
									data-index1={index1}
									data-index2={index2}
									id={`result-comment-button-${index1}-${index3}-${index}`}
									onClick={this.props.onCommentClick}
									onMouseEnter={this.props.onCommentMouseEnter}
									onMouseLeave={this.props.onCommentMouseLeave}
									data-device={device?`${device.device_name} ${device.os_name} ${device.browser_name?device.browser_name:""}`:""} 
									style={{right:"45px", paddingTop:"4px", display: device && this.props.commentCounts[`${device.device_name} ${device.os_name} ${device.browser_name}`]? "block" : "none"}}>comment</i>
									{device && this.props.commentCounts[`${device.device_name} ${device.os_name} ${device.browser_name}`]? <span aria-hidden="true" className="counter test-step-result-comments-button-counter">{this.props.commentCounts[`${device.device_name} ${device.os_name} ${device.browser_name}`]}</span> : ""}
								<i
									aria-hidden="true"
									className="material-icons result-outline-button" 
									draggable={true}
									onDragStart={this.props.onResultDragStart}
									onDragEnd={this.props.onResultDragEnd}
									data-column={index}
									data-row={row}
									id={`result-outline-button-${index1}-${index3}-${index}`} 
									onMouseDown={this.setOutline} 
									style={{
										position:"absolute", 
										right:"20px", 
										zIndex:"1", 
										cursor:"grab"
									}}
								>fullscreen</i>
								<select value={result}
									aria-label={
										`Test Case ${this.props.index1+1}.${this.props.index2+1}.
										${this.props.bugsCount ?` ${this.props.bugsCount} linked bug${this.props.bugsCount>1?"s":""}.`:""}
										${deviceTicketCounts?`${deviceTicketCounts} linked device bug${deviceTicketCounts>1?"s":""}.`:""}
										${this.props.commentCounts["no_device"]?` ${this.props.commentCounts["no_device"]} comment${this.props.commentCounts["no_device"]>1?"s":""}.`:""}
										${deviceCommentCounts?` ${deviceCommentCounts} device comment${deviceCommentCounts>1?"s":""}.`:""}
										${this.props.violationCount?` ${this.props.violationCount} a11y violation${this.props.violationCount>1?"s":""}.`:""}
										Press H for keyboard shortcuts.`}
									className={`test-case-result-select test-case-result-${index1}-${index2}-${index}`} 
									id={`test-case-result-${index1}-${index3}-${index}`}  
									onChange={this.handleResultChange}  
									style={{ lineHeight:"18px", backgroundColor: `${color}`, color: color === "white"?"transparent":"black", display: display}}
									onKeyDown={this.resultKeyDown}
									// onMouseDown={this.props.hideFocus} onBlur={this.props.showFocus}
									data-device={JSON.stringify(device)}
									data-index={index}
									data-index1={index1}
									data-index3={index3}
									tabIndex={0}
									onFocus={this.setCurrentColumn}
									// onFocus={}
									
								>
									<option style={{color: "black", backgroundColor:"white"}} value={0}>Untested</option>
									<option style={{color: "black", backgroundColor:"rgba(157, 222, 168, 0.5)"}} value={1}>Pass</option>
									<option style={{color: "black", backgroundColor:"rgba(220, 145, 141, 0.5)"}} value={2}>Fail</option>
									<option style={{color: "black", backgroundColor:"rgba(245, 238, 137, 0.5)"}} value={3}>Blocked</option>
									<option style={{color: "black", backgroundColor:"rgba(81, 155, 204, 0.5)"}} value={4}>Retest</option>
                  <option style={{color: "black", backgroundColor:"lavender"}} value={5}>N/A</option>
								</select>
							</div>
					)})
				
	}
}


 export default TestPassResultRow

