import React from "react";
import TextTransition, { presets } from "react-text-transition";

const ProjectImage = (projectDetails) => {
  const { icon_url, color, name } = projectDetails;
  return icon_url && icon_url != null ? (
    <div
      key={1}
      style={{
        width: "30px",
        marginRight: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          willChange: "width, height",
          transition: "width .666s, height .666s, transform .44s",
          width: "30px",
          height: "30px",
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          alt="User provided project"
          src={icon_url}
          width={30}
          height={30}
          style={{ borderRadius: "50%" }}
        />
      </div>
    </div>
  ) : (
    <div
      key={2}
      style={{
        width: "30px",
        marginRight: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          backgroundColor: color,
          willChange: "width, height, transform",
          transition: "width .666s, height .666s, .44s transform ease-in-out",
          width: "30px",
          height: "30px",
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <TextTransition
          direction="up"
          style={{ fontWeigth: 600, color: "white" }}
          text={name.substring(1, 0).toUpperCase()}
          springConfig={presets.wobbly}
        />
      </div>
    </div>
  );
};

export default ProjectImage;
