import React from "react";
import { CSSTransition } from "react-transition-group";

class ScopeSwitcher extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "last30",
      open: false,
      options: [],
    };
    this.options = {
      today: "Today",
      yesterday: "Yesterday",
      week: "This Week",
      month: "This Month",
      last30: "Last 30 days",
      allTime: "All",
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.submitData = this.submitData.bind(this);
    this.makeOptions = this.makeOptions.bind(this);
    this.setDeviceOpen = this.setDeviceOpen.bind(this);
    this.deviceCheckbox = React.createRef();
    this.deviceMenu = React.createRef();
    this.indicatorHandler = this.indicatorHandler.bind(this);
    this.handleOutsideClick = (e) => {
      if (this.deviceCheckbox && this.deviceCheckbox.current) {
        if (!this.deviceCheckbox.current.contains(e.target)) {
          this.setDeviceOpen();
        }
      }
    };
    this.selectedIcons = {
      today:
        "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Today_Outline.svg",
      yesterday:
        "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Yesterday_Outline.svg",
      week: "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Week%20-%20Outline.svg",
      last30:
        "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Last%2030%20Days%20-%20Outline.svg",
      month:
        "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_This%20Month%20-%20Outline.svg",
      allTime:
        "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_All%20Time%20-%20Outline.svg",
      custom:
        "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Custom%20Date%20-%20Outline.svg",
    };
  }

  setDeviceOpen() {
    if (!this.state.open === true) {
      window.addEventListener("click", this.handleOutsideClick);
      this.setState(
        {
          open: true,
        },
        () => {
          if (this.deviceMenu && this.deviceMenu.current) {
            var elem = this.deviceMenu.current;
            elem.style.maxHeight = this.props.maxHeight
              ? this.props.maxHeight
              : "270px";
            var optionElems = elem.children[0].children;
            optionElems.forEach((elm, index) => {
              elm.style.transitionDelay = `${
                (0.3 / optionElems.length + 0.05) * index
              }s`;
              elm.style.transform = "translateX(0px)";
              elm.style.opacity = "1";
            });
          }
        }
      );
    } else {
      window.removeEventListener("click", this.handleOutsideClick);
      if (this.deviceMenu && this.deviceMenu.current) {
        var elem = this.deviceMenu.current;
        elem.style.overflow = "hidden";
        elem.style.maxHeight = "0px";
        var optionElems = elem.children[0].children;
        optionElems.forEach((elm, index) => {
          elm.style.transitionDelay = `${
            (0.4 / optionElems.length) * (optionElems.length - (index + 1))
          }s`;
          elm.style.transform = "translateX(-14px)";
          elm.style.opacity = "0";
        });
        this.setState({
          open: false,
          selected: this.props.selected,
        });
      }
    }
  }

  handleSelect(e) {
    this.props.handler(e.target.value);
  }

  submitData(e) {
    e.preventDefault();
    var start = this.state.startDate;
    var end = this.state.endDate;
    this.props.customHandler(start, end);
    this.setState({ custom: false, customSubmited: true });
  }

  makeOptions() {
    var options = [
      {
        label: "Today",
        value: "today",
        handler: this.handleSelect,
        checked: this.props.selected == "today",
        icon:
          this.props.selected == "today"
            ? "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Today_blue.svg"
            : "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Today.svg",
      },
      {
        label: "Yesterday",
        value: "yesterday",
        handler: this.handleSelect,
        checked: this.props.selected == "yesterday",
        icon:
          this.props.selected == "yesterday"
            ? "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Yesterday_blue.svg"
            : "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Yesterday.svg",
      },
      {
        label: "This Week",
        value: "week",
        handler: this.handleSelect,
        checked: this.props.selected == "week",
        icon:
          this.props.selected == "week"
            ? "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Week_blue.svg"
            : "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Week.svg",
      },
      {
        label: "Last 30 Days",
        value: "last30",
        handler: this.handleSelect,
        checked: this.props.selected == "last30",
        icon:
          this.props.selected == "last30"
            ? "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Last%2030%20Days_blue.svg"
            : "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Last%2030%20Days.svg",
      },
      {
        label: "This Month",
        value: "month",
        handler: this.handleSelect,
        checked: this.props.selected == "month",
        icon:
          this.props.selected == "month"
            ? "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_This%20Month_blue.svg"
            : "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_This%20Month.svg",
      },
    ];
    var returnOptions = [];
    options.map((option, index) => {
      returnOptions.push(
        <div
          key={index}
          className={option.classList}
          style={{
            height: "35px",
            marginTop: index === 0 ? "10px" : "0px",
            marginBottom: index === options.length - 1 ? "15px" : "0px",
            opacity: "0",
            transform: "translateX(-14px)",
            transition: " opacity 0.3s, transform 0.3s",
          }}
        >
          <label style={{ cursor: "pointer" }}>
            <input
              onChange={this.handleSelect}
              className="filled-in dash-time-option"
              checked={option.checked}
              value={option.value}
              type="checkbox"
            />
            <span
              style={{
                whiteSpace: "nowrap",
                fontSize: "11pt",
                color: option.checked ? "#519acc" : "black",
                paddingLeft: "0px",
                verticalAlign: "13px",
              }}
            >
              {option.label}
            </span>
          </label>
        </div>
      );
    });
    return returnOptions;
  }

  componentDidMount() {
    this.setState({ loading: false });
  }

  render() {
    var text = this.options[this.props.selected];
    return (
      <div
        ref={this.deviceCheckbox}
        className="checkbox-menu-container"
        style={{ position: "relative", width: "fit-content" }}
      >
        <div
          className={`${
            this.state.open
              ? "devicebox-menu-selector-open"
              : "devicebox-menu-selector-closed"
          }`}
          onClick={this.setDeviceOpen}
          style={{
            height: "44px",
            color: this.state.active ? "white" : "initial",
            width: "130px",
            cursor: "pointer",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              lineHeight: "44px",
              whiteSpace: "nowrap",
              textTransform: "capitalize",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <span
              style={{
                display: "inline-block",
                width: "90px",
                textAlign: "center",
                paddingLeft: "8px",
                marginRight: "12px",
              }}
            >
              {text}
            </span>
            <i
              style={{
                verticalAlign: "middle",
                width: "38px",
                textAlign: "center",
              }}
              className="material-icons"
            >
              arrow_drop_down
            </i>
          </span>
        </div>
        <CSSTransition in={this.state.open} classNames="grow" timeout={200}>
          <div
            className="devicebox-menu"
            ref={this.deviceMenu}
            id="scope-checkbox-menu"
            style={{
              position: "absolute",
              transition: "max-height 0.5s 0.10s",
              zIndex: "13",
              backgroundColor: "#ffffffdb",
              right: "0px",
              top: "100%",
              padding: "0px 20px 0px 20px",
              maxWidth: "400px",
              maxHeight: "0px",
              overflow: "hidden",
              width: "130px",
            }}
          >
            <div id="dashTimeSwitcher">{this.makeOptions()}</div>
          </div>
        </CSSTransition>
      </div>
    );
  }
}

export default ScopeSwitcher;
