import React from "react";
import EodStats from "./eod_stats";
import EodReportsContainer from "../eod/eod_reports_container";

function EodRow(props) {
  function layout() {
    if (props.hasBugs || props.hasTestCases) {
      return (
        <div
          style={{ display: "flex", flex: 1, height: "100%", margin: "0 5px" }}
        >
          <div style={{ flex: ".4", marginLeft: "-5px", maxWidth: "325px" }}>
            <EodStats
              small={true}
              height={props.height * 0.4}
              projectId={props.project.id}
            />
          </div>
          <div style={{ flex: "1", marginRight: "-5px", height: "397px" }}>
            <EodReportsContainer
              handleViewEod={props.handleViewEod}
              handleEodModal={props.handleEodModal}
              eodReportCount={props.eodReportCount}
              projectId={props.project.id}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              flex: 1,
              width: "100%",
              height: "100%",
              maxHeight: "400px",
            }}
          >
            <div style={{ width: "100%" }}>
              <EodStats
                big={true}
                height={props.height * 0.4}
                projectId={props.project.id}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              marginTop: "10px",
              flex: 1,
              height: `400px`,
            }}
          >
            <EodReportsContainer
              handleViewEod={props.handleViewEod}
              handleEodModal={props.handleEodModal}
              eodReportCount={props.eodReportCount}
              projectId={props.project.id}
            />
          </div>
        </div>
      );
    }
  }

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "center",
        margin: "5px 0",
        height: "100%",
        flex: 1,
      }}
      className="dash-row-2"
    >
      {layout()}
    </div>
  );
}

export default EodRow;
